import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import menu2Fill from "@iconify/icons-eva/menu-2-fill";
// material
import { alpha, styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar, IconButton } from "@mui/material";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
// components
import { MHidden } from "../../components/@material-extend";
//
import Searchbar from "./Searchbar";
import AccountPopover from "./AccountPopover";
import LanguagePopover from "./LanguagePopover";
import NotificationsPopover from "./NotificationsPopover";
import { usePGIMode } from "../../Hooks/PGIModeContext";
import { useEffect } from "react";
import {
  ContentSettingApi,
  navbarListAPi,
} from "src/DAL/ContentSetting/ContentSetting";
import { useSnackbar } from "notistack";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { memberDetail } from "src/DAL/Profile/Profile";
import { get_root_value } from "src/utils/domUtils";
import { useState } from "react";
import {
  markNotificationAsRead,
  notificationList,
} from "src/DAL/Notification/Notification";
import { useNavigate } from "react-router-dom";
import ChatPopover from "src/components/ChatPopover";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 42;

// const RootStyle = styled(AppBar)(({ theme }) => ({
//   boxShadow: "none",
//   backdropFilter: "blur(6px)",
//   WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
//   backgroundColor: "rgba(18, 24, 38, 1)",
//   [theme.breakpoints.up("lg")]: {
//     width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
//   },
// }));
const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: get_root_value("--background-primary-color"),
}));
const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP + 20,
    padding: theme.spacing(0, 4),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const { mode, handleChangeMode } = usePGIMode();
  const { userInfo, handleUnreadMessageCount } = useContentSetting();
  const navigate = useNavigate();
  const [allNotifications, setAllNotifications] = useState([]);
  const [totalNotifications, setTotalNotifications] = useState(0);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [open, setOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const handleRedirect = async (
    notification_id,
    notification_type,
    notification
  ) => {
    setOpen(false);

    const result = await markNotificationAsRead(notification_id);

    if (result.code === 200) {
      getNotificationList();
      // setUnreadNotificationCount(unreadNotificationCount - 1);
      setAllNotifications((notifications) => {
        const notification_list = notifications.map((notification) => {
          if (notification._id === notification_id) {
            return {
              ...notification,
              is_read: true,
            };
          } else {
            return notification;
          }
        });
        return notification_list;
      });

      if (notification_type === "member_left_group") {
        navigate(`/group-feed/${notification.action_id}`);
      } else if (notification_type === "feed_added") {
        navigate(`/community-area-post-detail/${notification.action_id}`);
      } else if (notification_type === "feed_like") {
        navigate(`/community-area-post-detail/${notification.action_id}`);
      } else if (notification_type === "feed_comment_add") {
        navigate(`/community-area-post-detail/${notification.action_id}`);
      } else if (notification_type === "feed_coins_share") {
        navigate(`/community-area-post-detail/${notification.action_id}`);
      } else if (notification_type === "feed_pending") {
        navigate(`/community-area-post-detail/${notification.action_id}`);
      } else if (notification_type === "feed_approved") {
        // navigate(`/community-area`);
        navigate(`/community-area-post-detail/${notification.action_id}`);
      } else if (notification_type === "feed_rejected") {
        navigate(`/my-posts`, {
          state: { _id: notification.action_id },
        });
        // navigate(`/my-posts`);
      } else if (notification_type === "member_removed_from_group") {
        navigate(`/community-area`);
      } else if (notification_type === "member_added_to_group") {
        navigate(`/group-feed/${notification.action_id}`);
      } else if (notification_type === "form_completion_send") {
        navigate(`/forms`);
      } else if (notification_type === "feed_coins_share") {
        navigate(`/community-area-post-detail/${notification.action_id}`);
      } else if (notification_type === "message") {
        navigate(`/chat?chat_id=${notification?.message?.chat_id}`);
      } else if (notification_type === "add_support_ticket") {
        navigate(`/support-ticket`);
      } else if (notification_type === "friend_request") {
        navigate(`/chat/friend-list`, {
          state: {
            tabValue: 2,
          },
        });
      } else if (notification_type === "friend_request_rejection") {
        navigate(`/chat/friend-list`);
      } else if (notification_type === "friend_request_acceptance") {
        navigate(`/chat/friend-list`, {
          state: {
            tabValue: 0,
          },
        });
      } else if (notification_type === "accountability_partner_request") {
        navigate(`/chat/friend-list`, {
          state: {
            tabValue: 3,
          },
        });
      } else if (notification_type === "accountability_request_acceptance") {
        navigate(`/chat/friend-list`, {
          state: {
            tabValue: 4,
          },
        });
      } else if (notification_type === "friend_request_rejection") {
        navigate(`/chat/friend-list`, {
          state: {
            tabValue: 0,
          },
        });
      } else if (notification_type === "form_feedback") {
        navigate(`/forms-Details/${notification.form_slug}/${userInfo._id}`);
      } else if (notification_type === "form_feedback_edit") {
        navigate(`/forms-Details/${notification.form_slug}/${userInfo._id}`);
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getNotificationList = async () => {
    const result = await notificationList();
    if (result.code === 200) {
      console.log(result, "notification result");
      setAllNotifications(result.notifications);
      handleUnreadMessageCount(result?.unread_chat_counr);
      setTotalNotifications(result.total_notifications_count);
      setUnreadNotificationCount(result.unread_notifications);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  useEffect(() => {
    getNotificationList();
  }, []);

  return (
    <RootStyle>
      <ToolbarStyle sx={{ background: "#fff" }}>
        {/* <MHidden width="lgUp"> */}
        <IconButton
          onClick={onOpenSidebar}
          sx={{ mr: 1, color: "text.primary", marginRight: "auto" }}
        >
          <Icon icon={menu2Fill} />
        </IconButton>
        {/* </MHidden> */}

        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 0.7 }}
        >
          {/* <LanguagePopover /> */}
          <ChatPopover />
          <NotificationsPopover
            totalNotifications={totalNotifications}
            allNotifications={allNotifications}
            unreadNotificationCount={unreadNotificationCount}
            setUnreadNotificationCount={setUnreadNotificationCount}
            setAllNotifications={setAllNotifications}
            handleRedirect={handleRedirect}
            setOpen={setOpen}
            open={open}
          />
          <AccountPopover />
          {/* <FormControlLabel
            value="start"
            control={
              <Switch
                color="primary"
                value={mode}
                onChange={(e) => handleChangeMode(e.target.checked)}
              />
            }
            label="Dark Mode"
            labelPlacement="start"
          /> */}
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
