import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CircularProgress } from "@mui/material";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import { makeStyles } from "@mui/styles";
import { _api_add_friend, _api_get_friends_list } from "src/DAL/Chat/Chat";
import { useSnackbar } from "notistack";
import GymMembers from "./GymMembers";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
import { styled } from "@mui/material/styles";
import { OutlinedInput } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
    marginBottom: "20%",
  },
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  height: 44,
  color: "#000",
  border: "2px solid var(--portal-theme-primary)",
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": {
    width: 280,
  },
  "& fieldset": {
    borderWidth: `0px !important`,
    borderColor: `transparent !important`,
  },
}));

function FriendList() {
  const classes = useStyles();
  const observer = useRef();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [tabValue, setTabValue] = useState(state?.tabValue || 0);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [reciveCount, setReciveCount] = useState(0);
  const [sendCount, setSendCount] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [loadMorePath, setLoadMorePath] = useState("");
  const [searchText, setSearchText] = useState("");
  const [friends, setFriends] = useState([]);
  const [selectedFriend, setSelectedFriend] = useState([]);
  const [partnerList, setPartnerList] = useState([]);
  const load_More_Path = "api/friend_request/list_friends?page=0&limit=20";

  const get_friends_list = async (path, search, loading) => {
    let type = "";

    if (tabValue == 0) {
      type = "accepted";
    } else if (tabValue == 1) {
      type = "non_friend";
    } else if (tabValue == 2) {
      type = "sent_request";
    } else if (tabValue == 3) {
      type = "received_request";
    } else if (tabValue === 4) {
      type = "accountability_partner_list";
    }

    if (!loading) {
      setIsLoading(true);
      path = load_More_Path;
    }
    const result = await _api_get_friends_list(path, search, type);
    if (result.code === 200) {
      if (loading) {
        setFriends((prev) => [...prev, ...result.members]);
      } else {
        setFriends(result.members);
      }

      setIsLoading(false);
      setIsLoadingMore(false);
      setLoadMorePath(result?.load_more_url);
      setReciveCount(result?.receivedRequestCount);
      setSendCount(result?.sentRequestCount);
      setHasMore(result?.pagination?.hasNextPage);
    } else {
      setIsLoading(false);
      setIsLoadingMore(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleChangeTab = (event, newValue) => {
    setSearchText("");
    setSelectedFriend([]);
    setTabValue(newValue);
  };

  const loadMoreData = () => {
    setIsLoadingMore(true);
    get_friends_list(loadMorePath, searchText, true);
  };

  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, hasMore, loadMorePath, observer]
  );

  const handlePartner = (value, type) => {
    if (
      type === "cancel_friend_request" ||
      type === "unfriend_partner_Request"
    ) {
      setFriends((prev) =>
        prev.map((item) =>
          item._id === value ? { ...item, request_status: "accepted" } : item
        )
      );
    }
  };

  const handleAddFriend = async (value, type, partner_value) => {
    let post_data = {
      receiver_id: value,
      type: type,
    };
    const result = await _api_add_friend(post_data);
    if (result.code === 200) {
      if (type === "unfriend_partner_Request") {
        enqueueSnackbar(
          "Accountability Partner has been removed successfully",
          {
            variant: "success",
          }
        );
      } else {
        enqueueSnackbar(result.message, { variant: "success" });
      }

      if (partner_value) {
        handlePartner(value, type);
      }

      if (type === "send_accountability_request") {
        setFriends((prev) =>
          prev.map((item) =>
            item._id === value
              ? { ...item, request_status: "pending_accountability_partner" }
              : item
          )
        );
      }

      if (type === "send_request") {
        setSendCount((prev) => prev + 1);
      } else if (type === "cancel_friend_request") {
        setSendCount((prev) => prev - 1);
      }

      if (
        type === "reject_request" ||
        type === "accept_accountability_request"
      ) {
        setReciveCount((prev) => prev - 1);
      }

      if (type === "send_request" || type === "accept_request") {
        setSelectedFriend((prev) => [...prev, value]);
      } else {
        setSelectedFriend((prev) => prev.filter((friend) => friend !== value));
        if (
          tabValue === 2 ||
          type === "reject_request" ||
          type === "unfriendRequest" ||
          type === "accept_accountability_request" ||
          (type === "unfriend_partner_Request" && !partner_value)
        ) {
          setFriends((prev) => prev.filter((friend) => friend._id !== value));
        }
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleSearchText = (event) => {
    const value = event.target.value;
    const sanitizedValue = value.replace(/[^a-zA-Z0-9\s]/g, "");
    setSearchText(sanitizedValue);
  };

  const tab_data_object = {
    friends: friends,
    handleAddFriend: handleAddFriend,
    hasMore: hasMore,
    isLoadingMore: isLoadingMore,
    lastBookElementRef: lastBookElementRef,
    loadMoreData: loadMoreData,
    selectedFriend: selectedFriend,
    partnerList: partnerList,
  };

  const TABS_OPTIONS = [
    {
      title: "Friends List",
      component: isLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <GymMembers {...tab_data_object} type="friends_list" />
      ),
    },
    {
      title: "GYMRITE Members",
      component: isLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <GymMembers {...tab_data_object} type="gym_rite" />
      ),
    },
    {
      title: `Sent Requests (${sendCount})`,
      component: isLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <GymMembers {...tab_data_object} type="sent_request" />
      ),
    },
    {
      title: `Received Requests (${reciveCount})`,
      component: isLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <GymMembers {...tab_data_object} type="received_request" />
      ),
    },
    {
      title: `Accountability Partners`,
      component: isLoading ? (
        <CircularProgress className={classes.loading} color="primary" />
      ) : (
        <GymMembers {...tab_data_object} type="accountability_partner_list" />
      ),
    },
  ];

  useEffect(() => {
    if (!isLoading) {
      const timeoutId = setTimeout(() => {
        get_friends_list(loadMorePath, searchText);
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [searchText]);

  useEffect(() => {
    get_friends_list(load_More_Path, searchText);
  }, [tabValue]);

  return (
    <div className="container">
      <div className="row mt-3">
        <div className="col-6 d-flex align-items-center">
          <IconButton
            className="back-screen-button"
            onClick={() => {
              navigate(`/chat`);
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <h2 className="ms-3">Friends</h2>
        </div>
        <div className="col-6 d-flex align-items-center">
          <SearchStyle
            className="ms-auto"
            value={searchText}
            onChange={handleSearchText}
            placeholder="Search"
            startAdornment={
              <InputAdornment position="start">
                <Box
                  component={Icon}
                  icon={searchFill}
                  sx={{ color: "text.disabled" }}
                />
              </InputAdornment>
            }
          />
        </div>
        <MUICustomTabs
          data={TABS_OPTIONS}
          value={tabValue}
          handleChange={handleChangeTab}
        />
      </div>
    </div>
  );
}

export default FriendList;
