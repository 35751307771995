import React, { useState, useEffect, useRef } from "react";
// import FormsDrewer from "../../components/FormsDrewer/FormsDrewer";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Checkbox from "@mui/material/Checkbox";
import {
  Tooltip,
  Typography,
  Chip,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Button,
} from "@mui/material";
import "@fullcalendar/daygrid/main.css";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import { eventsListing } from "src/DAL/Calendar/Calendar";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import FullPagePopup from "src/components/FormsDialog/FullPagePopup";
import DetailPopUpModel from "src/components/Calender/DetailPopUpModel";
import EventDetailNew from "src/components/FormsDialog/EventDetailNew";

import DayCalendar from "src/pages/Calender/DayCalendar";
import WeekCalendar from "src/pages/Calender/WeekCalendar";

import EditWorkoutPopup from "src/pages/Calender/component/EditWorkoutPopup";
// import CustomConfirmation from "../ModalPopover/Confirmation";
import {
  delete_program_workout_Api,
  set_program_difficulty_level_api,
} from "src/DAL/programmeWorkout/programmeWorkout";
import CustomConfirmation from "../CustomConfirmation";
import { Icon } from "@iconify/react";
import { get_root_value } from "src/utils/domUtils";
import { s3baseUrl } from "src/config/config";
import CustomPopoverNew from "../CustomPopoverNew";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { show_proper_words } from "src/utils/constants";

const get_view_dates = (calendarValue) => {
  let tool = calendarValue;
  // if(calendarValue=="month")

  const startOfMonth = moment().startOf(tool);
  const daysToSubtract = startOfMonth.day() - 0; // 0 represents Sunday
  const previousSunday = startOfMonth.subtract(daysToSubtract, "days");
  let previousSundayDate = previousSunday.toDate();
  let add_days = 42;
  if (tool == "week") {
    add_days = 6;
  } else if (tool == "day") {
    add_days = 0;
  }
  let nextSaturday = startOfMonth.add(add_days, "days");
  let nextSaturdayDate = nextSaturday.toDate();
  if (tool == "day") {
    previousSundayDate = new Date();
    nextSaturdayDate = new Date();
  }

  return {
    start_date: previousSundayDate,
    end_date: nextSaturdayDate,
  };
};
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function GeneralCalendar({
  programWorkoutList,
  reorderedWorkoutList,
  setCurrentDate,
  showAddEvent,
  time_zone,
  currentDate,
  setIsLoading,
  isLoading,
  user_type,
  reloadList,
  calendar_title,
  hideArrowsAndButtons,
  programme,
  member_vdot,
  memberRewardData,
  setSubmitted,
  submitted,
}) {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();
  const classes = useStyles();
  const { userInfo } = useContentSetting();
  const [eventDetailData, setEventDetailData] = useState({});
  const [editDrawerState, setEditDrawerState] = useState(false);
  const [popupState, setPopupState] = useState(false);
  const [drawerState, setDrawerState] = useState(false);
  const [drawerStateUpdate, setDrawerStateUpdate] = useState(false);
  const [openItration, setopenItration] = useState(false);
  const [selectedTool, setSelectedTool] = useState("month");
  const [openDelete, setOpenDelete] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [editItrationDrawerState, setEditItrationDrawerState] = useState();
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredDate, setHoveredDate] = useState(null);
  const [selectedDate, setSelectDate] = useState("");
  const [dataDel, setDataDel] = useState();
  const [editWorkputData, setEditWorkputData] = useState();
  const calendarRef = useRef(null);
  const [openLevels, setOpenLevels] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [difficultyLevel, setDifficultyLevel] = useState(
    programme?.member_difficutly_level ||
      localStorage.getItem("difficultyLevel") ||
      ""
  );
  const [checked, setChecked] = useState(false);

  const handleChangeCheck = (event) => {
    setChecked(event.target.checked);
  };

  const handleAddLevels = async () => {
    if (!difficultyLevel) {
      return enqueueSnackbar(`Please Select Levels`, { variant: "error" });
    }

    let postData = {
      program_id: programme?._id?._id,
      member_id: userInfo?._id,
      difficulty_level: difficultyLevel,
    };

    let result = await set_program_difficulty_level_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setOpenLevels(false);
      localStorage.setItem("difficultyLevel", difficultyLevel);
      localStorage.setItem("isOpen", true);
      setIsOpen(true);
      reloadList();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleDeleteEvent = (eventToDelete) => {
    // Handle deletion logic here
  };
  const handleCellHover = (info) => {
    setHoveredDate(info.date);
  };
  const handleCellUnhover = (info) => {
    // setHoveredDate(null);
  };

  const get_dates = (tool) => {
    if (tool == "month") {
      return get_view_dates(tool);
    } else {
      return {
        start_date: moment().startOf(tool).toDate(),
        end_date: moment().endOf(tool).toDate(),
      };
    }
  };

  const handleOpenDrawer = (arg) => {
    setSelectDate(arg);
    setDrawerState(true);
  };

  const handleOpenEditDrawer = () => {
    setEditDrawerState(true);
  };

  const handleCloseEditDrawer = () => {
    setEditDrawerState(false);
  };

  const eventDetail = (event) => {
    setEventDetailData(event);
    setPopupState(true);
  };
  const handleAgreeDelete = (data) => {
    setDataDel(data);
    setOpenDelete(true);
  };
  const handleUpdate = () => {
    handleOpenEditDrawer();
  };
  const handleOpenEditIterationDrawer = () => {
    setEditItrationDrawerState(true);
  };
  const handleUpdateIteration = (data) => {
    console.log(data, "====clicked event");
    setEditWorkputData(data);
    handleOpenEditIterationDrawer();
  };
  const handleDelete = async (value) => {
    setOpenDelete(false);

    const result = await delete_program_workout_Api(
      dataDel?.program_workout_slug
    );
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      reloadList();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const EventCard = ({ workout, onDelete, onHover }) => {
    const [extra, setExtra] = useState(
      workout._def.extendedProps.workout_exercises
        .map((item) => {
          if (item.is_extra) {
            return item;
          }
          return [];
        })
        .flat()
    );
    const formatWithLeadingZeros = (value) => {
      return value < 10 ? `0${value}` : `${value}`;
    };
    const getMinutesForInterval = (mainindex, index) => {
      const list = [...workout._def.extendedProps.workout_exercises];
      const interval = list[mainindex].interval_duration;
      if (interval <= 60) {
        return index + 1;
      }
      if (interval % 60 == 0) {
        return `${
          (interval / 60) * index +
          1 +
          " – " +
          ((interval / 60) * index + interval / 60)
        }`;
      }
      let startInterval = interval * index;
      let endtInterval = interval * index + interval;
      let startintervalText =
        interval * index
          ? Math.floor(startInterval / 60) +
            " " +
            ":" +
            formatWithLeadingZeros(startInterval % 60)
          : 0;
      let endIntervalText =
        Math.floor(endtInterval / 60) +
        " " +
        ":" +
        formatWithLeadingZeros(endtInterval % 60);

      return `${startintervalText + " – " + endIntervalText}`;
    };
    // console.log(workout._def.extendedProps?.comment, "sdflksdlkfjklasjdklfja");
    return (
      <div
        className="calendar-workout-card"
        id={workout._def.extendedProps?._id}
        style={{
          border:
            editWorkputData?._id == workout._def.extendedProps?._id
              ? "2px solid var(--portal-theme-primary)"
              : "0px solid var(--portal-theme-primary)",
        }}
      >
        <div>
          <div
            className="calendar-workout-card-title"
            style={{
              backgroundColor: workout._def.extendedProps.workout_color,
            }}
          >
            <div
              className="calendar-workout-card-program d-flex justify-content-between"
              onClick={() => handleUpdateIteration(workout._def.extendedProps)}
            >
              <div> {workout._def.extendedProps?.workout_program?.title}</div>
              {workout._def.extendedProps?.workout_completed && (
                <div className="">
                  <Icon
                    icon="fluent-mdl2:completed-solid"
                    className="workout-complete-icon-container "
                  />
                </div>
              )}
            </div>
            <div className="d-flex justify-content-between">
              <div
                className="calendar-workout-card-workout"
                onClick={() =>
                  handleUpdateIteration(workout._def.extendedProps)
                }
              >
                {workout.title}
              </div>
              <div className="d-flex align-items-center">
                <Icon
                  onClick={() =>
                    handleUpdateIteration(workout._def.extendedProps)
                  }
                  icon={
                    workout._def.extendedProps.practice_type == "workout"
                      ? "mdi:gym"
                      : workout._def.extendedProps.practice_type == "food"
                      ? "fluent:food-24-filled"
                      : workout._def.extendedProps.practice_type == "mindset"
                      ? "flowbite:brain-outline"
                      : workout._def.extendedProps.practice_type == "video"
                      ? "lets-icons:video-fill"
                      : "mdi:gym"
                  }
                  className="workout-type-icon-container"
                />
                {!workout._def.extendedProps?.open ? (
                  <KeyboardArrowDownIcon
                    sx={{
                      cursor: "pointer",
                      opacity: 0.7,
                      fontSize: "20px",
                    }}
                    onClick={() => {
                      const list = [...events];
                      list[workout._def.extendedProps.index]["open"] = true;
                      setEvents(list);
                    }}
                  />
                ) : (
                  <KeyboardArrowUpIcon
                    sx={{
                      cursor: "pointer",
                      opacity: 0.7,
                      fontSize: "20px",
                    }}
                    onClick={() => {
                      const list = [...events];
                      list[workout._def.extendedProps.index]["open"] = false;
                      setEvents(list);
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          {workout._def.extendedProps?.open && (
            <div className="calendar-workout-card-exercises">
              <div
                className="calendar-workout-card-container py-1  "
                onClick={() =>
                  handleUpdateIteration(workout._def.extendedProps)
                }
              >
                {workout._def.extendedProps.practice_type == "video" ? (
                  <>
                    <div className="inner-div workout-calendar-exercise-card my-1">
                      {workout?._def?.extendedProps?.dumyImage ? (
                        <div
                          style={{
                            whiteSpace: "break-spaces",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative",
                          }}
                        >
                          <img
                            className="image-border"
                            src={workout?._def?.extendedProps?.dumyImage}
                            height="50"
                          />
                          <div className="overlay-icon">
                            <Icon
                              icon={"lets-icons:video-fill"}
                              className="workout-type-icon-container"
                            />
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            whiteSpace: "break-spaces",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative",
                          }}
                        >
                          <img
                            className="image-border"
                            src={
                              s3baseUrl +
                              workout?._def?.extendedProps?.video_thumbnail
                                ?.thumbnail_1
                            }
                            height="50"
                          />
                          <div className="overlay-icon">
                            <Icon
                              icon={"lets-icons:video-fill"}
                              className="workout-type-icon-container"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {workout._def.extendedProps.workout_exercises.map(
                  (exercise, index) => {
                    if (!exercise.is_extra) {
                      if (exercise?.exercise_type == "something_else") {
                        return (
                          <div
                            className="inner-div workout-calendar-exercise-card my-1 "
                            style={{ position: "relative" }}
                          >
                            {" "}
                            <div
                              style={{
                                whiteSpace: "break-spaces",
                              }}
                              className="py-1"
                            >
                              {exercise?.is_modified && (
                                <Tooltip
                                  title={
                                    exercise?.is_modified
                                      ? "Modified"
                                      : "Not Modified"
                                  }
                                >
                                  <span
                                    style={{
                                      display: "block",
                                      position: "absolute",
                                      top: "-5px",
                                      justifyContent: "center",
                                      zIndex: "1",
                                      right: "0px",
                                    }}
                                  >
                                    <Chip
                                      label={"Modified"}
                                      variant="contained"
                                      color={"error"}
                                      sx={{
                                        fontSize: "6px",
                                        height: "auto",
                                        width: "auto",
                                        borderRadius: "3px",
                                        "& .MuiChip-label": {
                                          paddingLeft: "3px",
                                          paddingRight: "3px",
                                        },
                                      }}
                                    />
                                  </span>
                                  {/* <Icon icon="arcticons:changedetection" /> */}
                                </Tooltip>
                              )}
                              {exercise?.is_completed && (
                                <Tooltip
                                  title={
                                    exercise?.is_completed
                                      ? "Completed"
                                      : "Not Completed"
                                  }
                                >
                                  <Icon
                                    icon="fluent-mdl2:completed-solid"
                                    className="calendar-workout-card-complete-exercises-icon mb-1"
                                  />
                                </Tooltip>
                              )}

                              {exercise?.title}
                            </div>
                          </div>
                        );
                      } else if (
                        exercise?.exercise_type == "amrap" &&
                        exercise?.emom_amrap &&
                        exercise?.emom_amrap.length > 0
                      ) {
                        return (
                          <div className="workout-calendar-Superset-card inner-div my-1">
                            <div className="text-center">
                              {" "}
                              {exercise?.is_completed && (
                                <Tooltip
                                  title={
                                    exercise?.is_completed
                                      ? "Completed"
                                      : "Not Completed"
                                  }
                                >
                                  <Icon
                                    icon="fluent-mdl2:completed-solid"
                                    className="calendar-workout-card-complete-exercises-icon mb-1"
                                  />
                                </Tooltip>
                              )}
                              AMRAP
                            </div>
                            {exercise?.emom_amrap?.map(
                              (interval, intervalIndex) => {
                                return (
                                  <div className="my-1">
                                    <div style={{ fontSize: "8px" }}>
                                      Movements
                                    </div>
                                    {interval?.map((exerData) => {
                                      return (
                                        <div
                                          className="inner-div"
                                          style={{
                                            whiteSpace: "break-spaces",
                                            marginBottom: "3px",
                                            // marginBottom: "1px",
                                            // backgroundColor:
                                            //   "rgba(255, 255, 255,255)",
                                            // overflow: "hidden",
                                            // textOverflow: "ellipsis",
                                          }}
                                        >
                                          {exerData?.exercise_info?.title}
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        );
                      } else if (
                        exercise?.exercise_type == "rounds_for_time" &&
                        exercise?.emom_amrap &&
                        exercise?.emom_amrap.length > 0
                      ) {
                        return (
                          <div className="workout-calendar-Superset-card inner-div my-1">
                            <div className="text-center">
                              {" "}
                              {exercise?.is_completed && (
                                <Tooltip
                                  title={
                                    exercise?.is_completed
                                      ? "Completed"
                                      : "Not Completed"
                                  }
                                >
                                  <Icon
                                    icon="fluent-mdl2:completed-solid"
                                    className="calendar-workout-card-complete-exercises-icon mb-1"
                                  />
                                </Tooltip>
                              )}
                              Rounds For Time
                            </div>
                            {exercise?.emom_amrap?.map(
                              (interval, intervalIndex) => {
                                return (
                                  <div className="my-1">
                                    <div style={{ fontSize: "8px" }}>
                                      Movements
                                    </div>
                                    {interval?.map((exerData) => {
                                      return (
                                        <div
                                          className="inner-div"
                                          style={{
                                            whiteSpace: "break-spaces",
                                            marginBottom: "3px",
                                            // marginBottom: "1px",
                                            // backgroundColor:
                                            //   "rgba(255, 255, 255,255)",
                                            // overflow: "hidden",
                                            // textOverflow: "ellipsis",
                                          }}
                                        >
                                          {exerData?.exercise_info?.title}
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        );
                      } else if (
                        exercise?.exercise_type == "reps_for_time" &&
                        exercise?.emom_amrap &&
                        exercise?.emom_amrap.length > 0
                      ) {
                        return (
                          <div className="workout-calendar-Superset-card inner-div my-1">
                            <div className="text-center">
                              {" "}
                              {exercise?.is_completed && (
                                <Tooltip
                                  title={
                                    exercise?.is_completed
                                      ? "Completed"
                                      : "Not Completed"
                                  }
                                >
                                  <Icon
                                    icon="fluent-mdl2:completed-solid"
                                    className="calendar-workout-card-complete-exercises-icon mb-1"
                                  />
                                </Tooltip>
                              )}
                              Reps For Time
                            </div>
                            {exercise?.emom_amrap?.map(
                              (interval, intervalIndex) => {
                                return (
                                  <div className="my-1">
                                    <div style={{ fontSize: "8px" }}>
                                      Movements
                                    </div>
                                    {interval?.map((exerData) => {
                                      return (
                                        <div
                                          className="inner-div"
                                          style={{
                                            whiteSpace: "break-spaces",
                                            marginBottom: "3px",
                                            // marginBottom: "1px",
                                            // backgroundColor:
                                            //   "rgba(255, 255, 255,255)",
                                            // overflow: "hidden",
                                            // textOverflow: "ellipsis",
                                          }}
                                        >
                                          {exerData?.exercise_info?.title}
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        );
                      } else if (
                        exercise?.exercise_type == "emom" &&
                        exercise?.emom_amrap &&
                        exercise?.emom_amrap.length > 0
                      ) {
                        return (
                          <div className="workout-calendar-Superset-card inner-div my-1">
                            <div className="text-center">
                              {" "}
                              {exercise?.is_completed && (
                                <Tooltip
                                  title={
                                    exercise?.is_completed
                                      ? "Completed"
                                      : "Not Completed"
                                  }
                                >
                                  <Icon
                                    icon="fluent-mdl2:completed-solid"
                                    className="calendar-workout-card-complete-exercises-icon mb-1"
                                  />
                                </Tooltip>
                              )}{" "}
                              EMOM
                            </div>
                            {exercise?.emom_amrap?.map(
                              (interval, intervalIndex) => {
                                return (
                                  <div className="my-1">
                                    <div style={{ fontSize: "8px" }}>
                                      Minute{" "}
                                      {getMinutesForInterval(
                                        index,
                                        intervalIndex
                                      )}
                                    </div>
                                    {interval?.map((exerData) => {
                                      return (
                                        <div
                                          className="inner-div"
                                          style={{
                                            whiteSpace: "break-spaces",
                                            marginBottom: "3px",
                                            // marginBottom: "1px",
                                            // backgroundColor:
                                            //   "rgba(255, 255, 255,255)",
                                            // overflow: "hidden",
                                            // textOverflow: "ellipsis",
                                          }}
                                        >
                                          {exerData?.exercise_info?.title}
                                        </div>
                                      );
                                    })}
                                  </div>
                                );
                              }
                            )}
                          </div>
                        );
                      } else if (
                        exercise?.sub_exercises &&
                        exercise?.sub_exercises.length > 0
                      ) {
                        return (
                          <div className="workout-calendar-Superset-card inner-div my-1">
                            {exercise?.sub_exercises?.map(
                              (exerData, exerIndex) => {
                                return (
                                  <div
                                    style={{ position: "relative" }}
                                    className="my-2"
                                  >
                                    <div
                                      className="inner-div"
                                      style={{
                                        whiteSpace: "break-spaces",
                                        marginBottom: "3px",
                                      }}
                                      // style={{
                                      //   overflow: "hidden",
                                      //   textOverflow: "ellipsis",
                                      //   fontSize: "11px",
                                      //   fontWeight: 500,
                                      // }}
                                    >
                                      {exerData?.is_modified && (
                                        <Tooltip
                                          title={
                                            exerData?.is_modified
                                              ? "Modified"
                                              : "Not Modified"
                                          }
                                        >
                                          <span
                                            style={{
                                              display: "block",
                                              position: "absolute",
                                              top: "-12px",
                                              justifyContent: "center",
                                              zIndex: "1",
                                              right: "0px",
                                            }}
                                          >
                                            <Chip
                                              label={"Modified"}
                                              variant="contained"
                                              color={"error"}
                                              sx={{
                                                fontSize: "6px",
                                                height: "auto",
                                                width: "auto",
                                                borderRadius: "3px",
                                                "& .MuiChip-label": {
                                                  paddingLeft: "3px",
                                                  paddingRight: "3px",
                                                },
                                              }}
                                            />
                                          </span>
                                          {/* <Icon icon="arcticons:changedetection" /> */}
                                        </Tooltip>
                                      )}
                                      {exerData?.is_completed && (
                                        <Tooltip
                                          title={
                                            exerData?.is_completed
                                              ? "Completed"
                                              : "Not Completed"
                                          }
                                        >
                                          <Icon
                                            icon="fluent-mdl2:completed-solid"
                                            className="calendar-workout-card-complete-exercises-icon"
                                          />
                                        </Tooltip>
                                      )}

                                      {exerData?.exercise_info?.title}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        );
                      } else
                        return (
                          <div
                            className="inner-div workout-calendar-exercise-card my-1 "
                            style={{ position: "relative" }}
                          >
                            {" "}
                            <div
                              style={{
                                whiteSpace: "break-spaces",
                              }}
                              className="py-1"
                            >
                              {exercise?.is_modified && (
                                <Tooltip
                                  title={
                                    exercise?.is_modified
                                      ? "Modified"
                                      : "Not Modified"
                                  }
                                >
                                  <span
                                    style={{
                                      display: "block",
                                      position: "absolute",
                                      top: "-5px",
                                      justifyContent: "center",
                                      zIndex: "1",
                                      right: "0px",
                                    }}
                                  >
                                    <Chip
                                      label={"Modified"}
                                      variant="contained"
                                      color={"error"}
                                      sx={{
                                        fontSize: "6px",
                                        height: "auto",
                                        width: "auto",
                                        borderRadius: "3px",
                                        "& .MuiChip-label": {
                                          paddingLeft: "3px",
                                          paddingRight: "3px",
                                        },
                                      }}
                                    />
                                  </span>
                                  {/* <Icon icon="arcticons:changedetection" /> */}
                                </Tooltip>
                              )}
                              {exercise?.is_completed && (
                                <Tooltip
                                  title={
                                    exercise?.is_completed
                                      ? "Completed"
                                      : "Not Completed"
                                  }
                                >
                                  <Icon
                                    icon="fluent-mdl2:completed-solid"
                                    className="calendar-workout-card-complete-exercises-icon mb-1"
                                  />
                                </Tooltip>
                              )}

                              {exercise?.exercise_info?.title}
                            </div>
                          </div>
                        );
                    }
                  }
                )}
                {extra && extra.length > 0 && (
                  <div>
                    <div className="text-center">
                      <Typography
                        sx={{
                          color: get_root_value("--portal-theme-primary"),
                          fontWeight: "500",
                        }}
                      >
                        <Icon icon="mdi:bicycle-electric" /> Extra Work
                      </Typography>
                      <div
                        style={{
                          whiteSpace: "break-spaces",
                          fontSize: 10,
                          opacity: 0.8,
                        }}
                      >
                        These exercises aren’t part of the original workout
                      </div>
                    </div>

                    <div>
                      {extra.map((exercise, index) => {
                        if (
                          exercise?.sub_exercises &&
                          exercise?.sub_exercises.length > 0
                        ) {
                          return (
                            <div className="workout-calendar-Superset-card inner-div my-1">
                              {exercise?.sub_exercises?.map(
                                (exerData, exerIndex) => {
                                  return (
                                    <div
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {/* {exerData?.is_completed && (
                                        <Icon
                                          icon="fluent-mdl2:completed-solid"
                                          className="calendar-workout-card-complete-exercises-icon mb-1"
                                        />
                                      )} */}
                                      {exerData?.exercise_info?.title}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          );
                        } else
                          return (
                            <div className="inner-div workout-calendar-exercise-card my-1">
                              {" "}
                              <div
                                style={{
                                  whiteSpace: "break-spaces",
                                }}
                              >
                                {/* {exercise?.is_completed && (
                                  <Icon
                                    icon="fluent-mdl2:completed-solid"
                                    className="calendar-workout-card-complete-exercises-icon"
                                  />
                                )} */}
                                {exercise?.exercise_info?.title}
                              </div>
                            </div>
                          );
                      })}
                    </div>
                  </div>
                )}
                <div className="mt-2">
                  {workout._def.extendedProps?.comment && (
                    <div
                      style={{
                        whiteSpace: "break-spaces",
                        fontSize: 10,
                        opacity: 0.8,
                        fontWeight: "500",
                        color: "black",
                      }}
                    >
                      <span
                        style={{
                          fontSize: 10,
                          opacity: 1,
                          fontWeight: "bold",
                          display: "block",
                          color: get_root_value("--portal-theme-primary"),
                        }}
                      >
                        Comment
                      </span>
                      {workout._def.extendedProps?.comment}
                    </div>
                  )}
                </div>
                <div className="mt-2">
                  {workout._def.extendedProps?.admin_comment && (
                    <div
                      style={{
                        whiteSpace: "break-spaces",
                        fontSize: 10,
                        opacity: 0.8,
                        fontWeight: "500",
                        color: "black",
                      }}
                    >
                      <span
                        style={{
                          fontSize: 10,
                          opacity: 1,
                          fontWeight: "bold",
                          display: "block",
                          color: get_root_value("--portal-theme-primary"),
                        }}
                      >
                        Reply
                      </span>
                      {workout._def.extendedProps?.admin_comment}
                    </div>
                  )}
                </div>
              </div>
            </div>
            // <div
            //   className="calendar-workout-card-container"
            //   onClick={() => handleUpdateIteration(workout._def.extendedProps)}
            // >

            // </div>
          )}
        </div>
      </div>
    );
  };
  const renderEventContent = (eventInfo) => {
    return <EventCard workout={eventInfo.event} onDelete={handleDeleteEvent} />;
  };
  const getEventListing = async (workout, time_zone) => {
    console.log(workout, "sdfkjlaksdjlkf");
    let all_events = [];
    // moment.tz.setDefault(time_zone);
    if (workout.length > 0) {
      all_events = workout.map((workout, i) => {
        return {
          ...workout,
          title: workout.title ? workout.title : "",
          color: workout.program_info?.color,
          workout_color: workout.program_info?.color,
          workout_title: workout.title ? workout.title : "",
          workout_exercises: workout.exercises,
          workout_program: workout.program_info,
          status: workout.status,
          date: moment(workout.workout_date).format("YYYY-MM-DD"),
          index: i,
          order: i,
          open: true,
          editable: false,
        };
      });
    }
    console.log(all_events, "=============all_events===============");
    // setEvents(all_events);
    const eventsDict = {};
    all_events.forEach((event) => {
      eventsDict[event._id] = event;
    });

    // List to hold the reordered events
    const reorderedEvents = [];
    let remainingEvents = [...all_events];

    // Reorder events based on reordered_workouts
    reorderedWorkoutList.forEach((workout) => {
      const { workout_date, reorder_workouts } = workout;
      reorder_workouts.forEach((workout_id, index) => {
        if (eventsDict[workout_id]) {
          const event = eventsDict[workout_id];
          event.order = index;
          reorderedEvents.push(event);
          // Remove the event from remainingEvents
          const remainingIndex = remainingEvents.findIndex(
            (e) => e._id === workout_id
          );
          if (remainingIndex > -1) {
            remainingEvents.splice(remainingIndex, 1);
          }
        }
      });

      // Add remaining events of the same date that are not in reorder_workouts
      const remainingSameDateEvents = remainingEvents.filter(
        (event) => event.workout_date === workout_date
      );

      remainingSameDateEvents.forEach((event) => {
        reorderedEvents.push(event);
      });

      // Remove the same date events from remainingEvents
      remainingEvents = remainingEvents.filter(
        (event) => event.workout_date !== workout_date
      );
    });

    // Add any other remaining events
    reorderedEvents.push(...remainingEvents);

    console.log(reorderedEvents, "helo=====reorderedEventsreorderedEvents");
    let all_reorder_events = reorderedEvents.map((workout, i) => {
      return {
        ...workout,
        title: workout.title ? workout.title : "",
        color: workout.program_info?.color,
        workout_color: workout.program_info?.color,
        workout_title: workout.title ? workout.title : "",
        workout_exercises: workout.exercises,
        workout_program: workout.program_info,
        status: workout.status,
        open: true,
        date: moment(workout.workout_date).format("YYYY-MM-DD"),
        index: i,
        order: i,
        editable: false,
      };
    });
    setEvents(all_reorder_events);
  };

  const TOOL_BUTTONS = [
    {
      text: "month",
    },
    {
      text: "week",
    },
    {
      text: "day",
    },
  ];

  const handleChangeTools = (tool) => {
    let dates = get_dates(tool);
    setCurrentDate(dates);
    setSelectedTool(tool);
  };
  const handleCloseEditIterationDrawer = () => {
    setEditItrationDrawerState(false);
  };
  const getCenteredText = () => {
    let text = moment(new Date()).format("MMM YYYY");
    if (selectedTool == "month") {
      // text = moment(currentDate.start_date).add(1, "month").format("MMM YYYY");

      if (calendarRef?.current) {
        const calendarApi = calendarRef?.current.getApi();
        const currentMonth = calendarApi.getDate().getMonth(); // Get the current month (0-based index)
        const currentYear = calendarApi.getDate().getFullYear(); // Get the current year

        text = moment().month(currentMonth).format("MMM") + " " + currentYear;
      }
      if (
        moment(new Date()).isBetween(
          currentDate.start_date,
          currentDate.end_date,
          null,
          "[)"
        )
      ) {
        // console.log(
        //   moment(new Date()).isBetween(
        //     currentDate.start_date,
        //     currentDate.end_date,
        //     null,
        //     "[)"
        //   ),
        //   "dsfjldsjfkasdjfka"
        // );
      }
    } else if (selectedTool == "week") {
      let month = moment(currentDate.start_date).format("MMM");
      let start_date = moment(currentDate.start_date).format("DD");
      let end_date = moment(currentDate.end_date).format("DD");
      let year = moment(currentDate.start_date).format("YYYY");
      text = `${month} ${start_date} - ${end_date} ${year}`;
    } else {
      text = moment(currentDate.start_date).format("MMM DD, YYYY");
    }
    return text;
  };

  const hanldeChangeDates = (value) => {
    let start_date = currentDate.start_date;
    let end_date = currentDate.end_date;

    if (calendarRef?.current) {
      const calendarApi = calendarRef?.current.getApi();

      const currentView = calendarApi.view;
      const startDate = currentView.activeStart;
      const endDate = currentView.activeEnd;
      start_date = startDate;
      end_date = endDate;
      console.log("Start date of the current month:", startDate, endDate);
    }
    let new_object = {};
    if (value == "next") {
      new_object = {
        start_date: moment(start_date),
        end_date: moment(end_date),
      };
    } else {
      new_object = {
        start_date: moment(start_date),
        end_date: moment(end_date),
      };
    }
    console.log(new_object, "djflksdklfla");
    setCurrentDate(new_object);
  };

  const handleChangeNextPrev = (value) => {
    if (selectedTool == "month") {
      let classToClick = "fc-next-button";
      if (value == "prev") {
        classToClick = "fc-prev-button";
      }
      let click_element = document.getElementsByClassName(classToClick)[0];
      click_element.click();
    }
    hanldeChangeDates(value);
  };

  const handleClickDate = (workout) => {
    setSelectedTool("day");
    setCurrentDate({
      start_date: workout.dateStr,
      end_date: workout.dateStr,
    });
  };
  const renderDayCell = (arg) => {
    // Render custom content for each date cell

    const isHovered =
      hoveredDate && arg.date.getTime() === hoveredDate.getTime();
    //  const { dayNumberText, day } = eventInfo;
    return (
      <div
        style={{
          position: "relative",
          height: "100%",
          marginTop: -10,
        }}
      >
        <span>{arg?.dayNumberText}</span>{" "}
        <span
          className="day-cell-name"
          style={{
            opacity: 0.5,
            fontSize: 10,
          }}
        >
          {moment(arg?.date).format("ddd")}
        </span>
      </div>
    );
  };

  const goToToday = () => {
    if (calendarRef?.current) {
      const calendarApi = calendarRef.current.getApi();
      const today = new Date();
      const todayMonth = today.getMonth();

      const isCurrentMonth =
        calendarApi.view.activeStart.getMonth() === todayMonth;

      if (isCurrentMonth) {
        const todayDate = today.toISOString().slice(0, 10);
        const todayCell = document.querySelector(
          `.fc-day[data-date="${todayDate}"]`
        );
        if (todayCell) {
          todayCell.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      } else {
        calendarApi.gotoDate(today);
        const newDateRange = {
          start_date: calendarApi.view.activeStart,
          end_date: calendarApi.view.activeEnd,
        };
        setCurrentDate(newDateRange);
        setTimeout(() => {
          const todayDate = today.toISOString().slice(0, 10);
          const todayCell = document.querySelector(
            `.fc-day[data-date="${todayDate}"]`
          );
          if (todayCell) {
            todayCell.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }, 1000);
      }
    }
  };

  let width_cal = 0;
  const screenWidth = window.innerWidth;

  const element = document.querySelector(".simplebar-wrapper");
  if (element) {
    width_cal = screenWidth - element.offsetWidth - 36;
  } else {
    width_cal = screenWidth;
  }

  useEffect(() => {
    if (
      !programme?.member_difficutly_level &&
      programme?._id?.difficulty_level &&
      !localStorage.getItem("isOpen")
    ) {
      setOpenLevels(true);
    }
  }, [programme?.member_difficutly_level]);

  useEffect(() => {
    let start_date = currentDate.start_date;
    let end_date = currentDate.end_date;

    if (calendarRef?.current) {
      const calendarApi = calendarRef?.current.getApi();

      const currentView = calendarApi.view;
      const startDate = currentView.activeStart;
      const endDate = currentView.activeEnd;
      start_date = startDate;
      end_date = endDate;
    }
  }, [calendarRef]);
  useEffect(() => {
    let timeout;
    if (!isLoading && calendarRef?.current) {
      timeout = setTimeout(() => {
        const calendarApi = calendarRef?.current.getApi();
        const today = new Date().toISOString().slice(0, 10); // Get today's date in YYYY-MM-DD format
        const todayCell = document.querySelector(
          `.fc-day[data-date="${today}"]`
        ); // Find the DOM element corresponding to today's date
        if (todayCell) {
          todayCell.scrollIntoView({ behavior: "smooth", block: "center" }); // Scroll the cell into view
        }
      }, 500);
    }
    return () => clearTimeout(timeout);
  }, [isLoading]);
  useEffect(() => {
    getEventListing(programWorkoutList, time_zone);
  }, [programWorkoutList, submitted]);
  useEffect(() => {
    if (editWorkputData?._id) {
      // Get today's date in YYYY-MM-DD format
      setTimeout(() => {
        console.log(editWorkputData?._id, "scrollkdsfkjhsdkjfal");
        const todayCell = document.querySelector(
          `.calendar-workout-card[id="${editWorkputData?._id}"]`
        ); // Find the DOM element corresponding to today's date

        todayCell.scrollIntoView({ behavior: "instant", block: "center" }); // Scroll the cell into view
      }, 0); // Delay by 1 second (1000 milliseconds)
    }
  }, [events, editWorkputData]);
  const eventOrderFunction = (a, b) => {
    // console.log(a, b, "a and  b");
    return a.order - b.order;
  };
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container-fluid">
      <div className="row fixed_top" style={{ width: `${width_cal}px` }}>
        <div className="col-md-9 col-12">
          <h2 className="font-size-on-sm">
            <IconButton
              className="back-screen-button mb-0 me-2"
              onClick={() => {
                navigate(-1);
                localStorage.removeItem("difficultyLevel");
                localStorage.removeItem("isOpen");
              }}
            >
              <ArrowBackIcon />
            </IconButton>
            {calendar_title}
          </h2>
        </div>
        {difficultyLevel && (
          <div className="col-md-3 col-12 text-end">
            <p className="mb-2 text-muted">
              Difficulty Level : {show_proper_words(difficultyLevel)}
            </p>
          </div>
        )}
        <div className="col-4 mt-2 text-start">
          {!hideArrowsAndButtons && (
            <div class="btn-group toollbar-group-buttons group-buttons-font-size-on-sm">
              <button
                className={`small-contained-button`}
                onClick={() => {
                  handleChangeNextPrev("prev");
                }}
              >
                <ArrowBackIosIcon />
              </button>
              <button
                className={`small-contained-button`}
                onClick={() => {
                  handleChangeNextPrev("next");
                }}
              >
                <ArrowForwardIosIcon />
              </button>
            </div>
          )}
        </div>
        <div className="col-4 mt-2 text-center">
          <h3
            className="font-size-on-sm mb-0"
            style={{
              color: "#04abbb",
            }}
          >
            {getCenteredText()}
          </h3>
        </div>
        <div className="col-4 mt-2 text-end">
          <div className="group-buttons-font-size-on-sm">
            <button
              className={`small-contained-outlined-button`}
              onClick={() => {
                goToToday();
              }}
            >
              Today
            </button>
          </div>
        </div>
      </div>
      <div className="row p_top_10">
        <div
          className="full-calendar full-calendar-new-flow mt-3"
          onMouseLeave={() => setHoveredDate(null)}
        >
          {selectedTool == "month" ? (
            <FullCalendar
              ref={calendarRef}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: "today prev,next",
                center: "title",
                right: "dayGridMonth,timeGridWeek,timeGridDay",
              }}
              className="fc-day-sun fc-toolbar-chunk fc eventimage fc-col-header-cell-cushion fc-today-button fc-right fc-prev-button fc-right fc-next-button fc-dayGridMonth-button fc-timeGridWeek-button fc-timeGridDay-button fc-daygrid-day-number fc-daygrid-day-top"
              eventContent={renderEventContent}
              events={events}
              initialView="dayGridMonth"
              dayCellContent={renderDayCell}
              editable={true}
              selectable={true}
              selectMirror={true}
              eventOrder={eventOrderFunction}
              weekends={true}
              fixedWeekCount={false}
              moreLinkContent={function (args) {
                return "+" + args.num;
              }}
              moreLinkClick={function (args) {
                handleClickDate(args);
              }}
              dayCellDidMount={(arg) => {
                const cell = arg.el;

                cell.addEventListener("mouseover", () => {
                  handleCellHover(arg);
                });

                cell.addEventListener("mouseout", () => {
                  handleCellUnhover(arg);
                });
              }}
            />
          ) : selectedTool == "week" ? (
            <WeekCalendar
              events={events}
              handleClick={eventDetail}
              currentDate={currentDate}
              isLoading={isLoading}
            />
          ) : (
            <DayCalendar
              events={events}
              handleClick={eventDetail}
              currentDate={currentDate}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>

      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to Delete?"}
        handleAgree={handleDelete}
      />

      <EditWorkoutPopup
        open={editItrationDrawerState}
        setOpen={setEditItrationDrawerState}
        date={selectedDate}
        programme={programme}
        data={editWorkputData}
        reloadList={reloadList}
        member_vdot={member_vdot}
        memberRewardData={memberRewardData}
        setEvents={setEvents}
        setEditWorkputData={setEditWorkputData}
        events={events}
        setSubmitted={setSubmitted}
      />

      <CustomPopoverNew
        is_hide_icon={programme?.member_difficutly_level ? false : true}
        width="550px"
        isOpenPop={openLevels}
        isClosePop={
          programme?.member_difficutly_level ? setOpenLevels : () => {}
        }
        title={"Difficulty Levels"}
        componentToPassDown={
          <div className="px-4 pb-3">
            <FormControl fullWidth sx={{ mb: 2, mt: 2 }} required>
              <InputLabel id="demo-simple-select-label">
                Difficulty Levels
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // multiple
                name="difficulty_levels"
                value={difficultyLevel}
                label="Difficulty Levels"
                onChange={(e) => {
                  setDifficultyLevel(e.target.value);
                }}
              >
                <MenuItem value="novice">Novice</MenuItem>
                <MenuItem value="intermediate">Intermediate</MenuItem>
                <MenuItem value="advanced">Advanced</MenuItem>
              </Select>
            </FormControl>
            <div
              className="mb-2"
              style={{
                width: "500px",
                maxWidth: "100%",
              }}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox checked={checked} onChange={handleChangeCheck} />
                  }
                  label="Workouts with your selected difficulty level will be assigned to you for this programme. (To change it, please submit a support ticket.)"
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      color: "gray",
                      fontSize: "14px",
                    },
                  }}
                />
              </FormGroup>
            </div>
            <div className="text-end">
              <Button
                variant="contained"
                disabled={!checked}
                onClick={handleAddLevels}
              >
                Save
              </Button>
            </div>
          </div>
        }
      />
    </div>
  );
}

export default GeneralCalendar;
