import { Button, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import { manage_booking_api } from "src/DAL/HelpingVideos/AdminHelpingVideosCategory";
import { makeStyles } from "@mui/styles";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import { s3baseUrl } from "src/config/config";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function RedirectPage() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [mangeBooking, setMangeBooking] = useState([]);

  const getManageBooking = async () => {
    const result = await manage_booking_api();
    if (result.code == 200) {
      setMangeBooking(result.booking_information);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleRedirect = (url) => {
    if (url) {
      window.open(url, "_blank");
    }
  };

  useEffect(() => {
    getManageBooking();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2>Booking</h2>
        </div>
        {mangeBooking.length > 0 ? (
          mangeBooking.map((value, index) => {
            return (
              <div className="col-lg-4 col-md-4 col-sm-12 mb-4" key={index}>
                <div
                  className="card mt-4 pods-cards-shadow cursor h-100"
                  onClick={() => handleRedirect(value?.redirect_url)}
                >
                  <img
                    src={s3baseUrl + value?.image?.thumbnail_1}
                    className="card-img-top pods-image"
                    alt="Programme"
                  />
                  <div className="card-body text-start position-relative">
                    <h3 className="h2-heading">{value.title}</h3>
                    <p className="text-muted mb-0">{value.program}</p>
                    <p className="programme-card-desc py-2">
                      {value.description}
                    </p>
                    <div className="d-flex justify-content-end position-absolute bottom-0 end-0 p-3">
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => handleRedirect(value?.redirect_url)}
                      >
                        Open Booking Page
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <RecordNotFound title="Data Not Found" />
        )}
      </div>
    </div>
  );
}
