import PropTypes from "prop-types";
import { useEffect } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Link,
  Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
} from "@mui/material";
// components
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import { MHidden } from "../../components/@material-extend";
//
import sidebarConfig from "./SidebarConfig";
import account from "../../_mocks_/account";
import { logout } from "../../DAL/Login/Login";
import { baseUri } from "../../config/config";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { navbarListAPi } from "src/DAL/ContentSetting/ContentSetting";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { navbarList, handleNavbarList, consultantInfo, userInfo } =
    useContentSetting();
  const navigate = useNavigate();
  const handleLogout = async () => {
    const formdata = new FormData();
    formdata.append("token", localStorage.getItem("token"));
    const result = await logout(formdata);
    if (result.code === 200) {
      localStorage.clear();
      navigate("/login");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const getNavbarList = async () => {
    const navbar_result = await navbarListAPi();
    console.log(navbar_result, "navbar_resultnavbar_result111111");
    if (navbar_result.code === 200) {
      handleNavbarList({
        ...navbar_result.menus,
        ...navbar_result?.booking_information,
      });
      localStorage.setItem(
        `navbar_list`,
        JSON.stringify({
          ...navbar_result.menus,
          ...navbar_result?.booking_information,
        })
      );
    }
  };

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);
  useEffect(() => {
    getNavbarList();
  }, []);

  const renderContent = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box
          component={RouterLink}
          to="/"
          sx={{
            display: "inline-flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Logo />
        </Box>
      </Box>

      <NavSection
        navConfig={sidebarConfig(navbarList, consultantInfo, userInfo)}
      />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  let settings_routes = [
    "/payment-request",
    "/transaction",
    "/manage-card",
    "/change-card",
    "/manage-notification",
    "/qr-scanner",
    "/pending-request",
    "/active-request",
    "/manage-subscription",
    "/live-events/",
  ];

  let isMatchRoute = settings_routes.some((route) => pathname.includes(route));
  if (isMatchRoute) {
    return (
      <div>
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
            className: "sidebar-drawer",
          }}
        >
          {renderContent}
        </Drawer>
      </div>
    );
  }

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
            className: "sidebar-drawer",
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
