// Modal.js
import React from "react";
import ReactDOM from "react-dom";
import { s3baseUrl } from "src/config/config";
import { animatedImage } from "src/assets";
import { useWindowSize } from "react-use";
import Confetti from "react-confetti";

const CongratulationAnimation = ({ rewardData }) => {
  const { width, height } = useWindowSize();
  return ReactDOM.createPortal(
    <div className="modal-overlay">
      <Confetti
        // width={width}
        // height={height}
        gravity={0.2}
        numberOfPieces={400}
        drawShape={(ctx) => {
          const radius = 12;
          const shadowOffset = 2;

          // Outer shadow
          ctx.beginPath();
          ctx.arc(0, 0, radius + shadowOffset, 0, Math.PI * 2, false);
          ctx.fillStyle = "rgba(0, 0, 0, 0.3)";
          ctx.fill();
          ctx.closePath();

          // Coin body
          ctx.beginPath();
          ctx.arc(0, 0, radius, 0, Math.PI * 2, false);
          ctx.fillStyle = "#FFD700";
          ctx.fill();
          ctx.closePath();

          ctx.beginPath();
          ctx.arc(0, 0, radius - 3, 0, Math.PI * 2, false);
          ctx.fillStyle = "#F5C400";
          ctx.fill();
          ctx.closePath();

          ctx.beginPath();
          ctx.arc(0, 0, radius, 0, Math.PI * 2, false);
          ctx.lineWidth = 2;
          ctx.strokeStyle = "#C68E17";
          ctx.stroke();
          ctx.closePath();
        }}
      />
    </div>,
    document.getElementById("modal-root")
  );
};

export default CongratulationAnimation;
