import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import moment from "moment";
import { useSnackbar } from "notistack";
import Checkbox from "@mui/material/Checkbox";
import React, { useEffect, useState } from "react";
import { fixedProgramAcces } from "src/DAL/Programmes/Programmes";

function ConfirmationWithDate({
  open,
  setOpen,
  handleAgree,
  title,
  programId,
  reload,
}) {
  const [date, setDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [difficultyLevel, setDifficultyLevel] = useState(
    programId?.member_difficutly_level || ""
  );
  const [checked, setChecked] = useState(false);

  const handleChangeCheck = (event) => {
    setChecked(event.target.checked);
  };

  const { enqueueSnackbar } = useSnackbar();

  const handleCloseDialog = () => {
    setOpen(false);
  };
  const ProgramAccess = async () => {
    if (!difficultyLevel && programId?._id?.difficulty_level) {
      return enqueueSnackbar(`Please Select Levels`, { variant: "error" });
    }
    if (!checked && programId?._id?.difficulty_level) {
      return enqueueSnackbar(`Please check the required box to proceed.`, {
        variant: "error",
      });
    }

    setIsLoading(true);
    let postData = {
      program_id: programId?._id?._id,
      difficulty_level: difficultyLevel,
      start_date: moment(date).format("YYYY-MM-DD"),
    };
    if (!date) {
      enqueueSnackbar("Please Select Date", { variant: "error" });
      setIsLoading(false);
      return;
    }
    console.log(postData, "sdlkfjaklsdjfkal");
    const result = await fixedProgramAcces(postData);
    if (result.code === 200) {
      setIsLoading(false);
      reload();
      handleCloseDialog();
      localStorage.removeItem("difficultyLevel");
      localStorage.removeItem("isOpen");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (open) {
      setDate(null);
      setIsLoading(false);
    }
  }, [open]);
  return (
    <>
      <Dialog
        open={open}
        onClose={() => handleCloseDialog()}
        PaperProps={{
          style: {
            backgroundColor: "white",
            color: "black",
            boxShadow: "none",
          },
        }}
      >
        <DialogTitle>
          {title ? title : "Are you sure you want to take this action?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <TextField
              sx={{ mt: 2 }}
              id="outlined-basic"
              label="Start Date"
              variant="outlined"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              placeholder="Start Date"
              fullWidth
              required
              name="no_of_weeks"
              value={date}
              inputProps={{
                min: moment().format("YYYY-MM-DD"), // Set the minimum date to today's date
              }}
              onChange={(e) => {
                setDate(e.target.value);
              }}
            />
            {programId?._id?.difficulty_level && (
              <>
                <FormControl fullWidth sx={{ mb: 2, mt: 2 }} required>
                  <InputLabel id="demo-simple-select-label">
                    Difficulty Levels
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="difficulty_levels"
                    value={difficultyLevel}
                    label="Difficulty Levels"
                    onChange={(e) => {
                      setDifficultyLevel(e.target.value);
                    }}
                  >
                    <MenuItem value="novice">Novice</MenuItem>
                    <MenuItem value="intermediate">Intermediate</MenuItem>
                    <MenuItem value="advanced">Advanced</MenuItem>
                  </Select>
                </FormControl>
                <div
                  className="mb-2"
                  style={{
                    width: "500px",
                    maxWidth: "100%",
                  }}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={handleChangeCheck}
                        />
                      }
                      label="Workouts with your selected difficulty level will be assigned to you for this programme. (To change it, please submit a support ticket.)"
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          color: "gray",
                          fontSize: "14px",
                        },
                      }}
                    />
                  </FormGroup>
                </div>
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            className="model-button-hover"
            onClick={() => handleCloseDialog()}
          >
            Cancel
          </Button>
          <Button
            className="model-button-hover"
            disabled={isLoading}
            onClick={ProgramAccess}
          >
            {isLoading ? "Submitting..." : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
      {/* New to solve delete issue  */}
    </>
  );
}

export default ConfirmationWithDate;
