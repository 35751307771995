import { invokeApi } from "../../bl_libs/invokeApi";

export const delete_program_workout_Api = async (slug) => {
  const requestObj = {
    path: `/api/program_workout/${slug}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const add_program_workout_api = async (data) => {
  const requestObj = {
    path: `/api/program_workout`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const update_program_workout_api = async (data) => {
  const requestObj = {
    path: `api/member/log_workout/by_member`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const programme_workout_list_api = async (data) => {
  const requestObj = {
    path: `api/member/get_workouts/by_member/v1/?start=${data.start_date}&end=${data.end_date}&program=${data.slug}&view=program`,
    // api/member/get_workouts/by_member/v1/?start=2024-05-23&end=2024-05-25&program=664f39ba44938b6af2a8689b&view=program
    // path: `api/member/get_workouts/by_member/?start=${data.start_date}&end=${data.end_date}&program=${data.slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const programme_general_workout_list_api = async (data) => {
  const requestObj = {
    path: `api/member/get_workouts/by_member/v1/?start=${data.start_date}&end=${data.end_date}&program=${data.slug}&view=calendar`,
    // path: `api/member/get_workouts/by_member/?start=${data.start_date}&end=${data.end_date}&program=${data.slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const programme_workout_exercise_history_list_api = async (data) => {
  const requestObj = {
    path: `api/member/member_exercise_history/by_admin?exercise_id=${data.exercise}&member_id=""`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const programme_workout_exercise_info_api = async (data) => {
  const requestObj = {
    path: `api/exercise/exercise_details_for_preview/${data}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const programmeDetailApi = async (data) => {
  const requestObj = {
    path: `api/program/get_program/${data}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const set_program_difficulty_level_api = async (data) => {
  const requestObj = {
    path: `api/member/set_program_difficulty_level`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
