import {
  Avatar,
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Box,
  Typography,
  Chip,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SyncDisabledIcon from "@mui/icons-material/SyncDisabled";
import { styled } from "@mui/material/styles";
import React, { useEffect, useMemo, useRef, useState } from "react";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import SyncIcon from "@mui/icons-material/Sync";

import EditIcon from "@mui/icons-material/Edit";
import { makeStyles } from "@mui/styles";

import CloseIcon from "@mui/icons-material/Close";

import { s3baseUrl } from "src/config/config";
import InfoIcon from "@mui/icons-material/Info";

// import CustomPopovers from "src/components/GeneralComponents/CustomPopovers";

import ConfirmationWithDescription from "src/components/ConfirmationWithDescription";
import Label from "src/components/Label";
import ParameterModel from "./component/ParameterModel";
import { Icon } from "@iconify/react";
// import ExerciseHistoryModel from "./components/ExerciseHistoryModel";
// import { programme_workout_exercise_history_list_api } from "src/DAL/programmeWorkout/programmeWorkout";
import ToshowExercise from "./component/modalToshowExercise";
import ExerciseHistoryModel from "./component/ExerciseHistoryModel";
import { programme_workout_exercise_history_list_api } from "src/DAL/programmeWorkout/programmeWorkout";
import ModalToShowAnExerciseInfo from "./component/ModalToShowAnExerciseInfo";
import CongratulationAnimation from "./component/CongratulationAnimation";
import { max } from "lodash";

const ITEM_HEIGHT = 70;
const ITEM_PADDING_TOP = 8;

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  loading2: {
    position: "fixed",
    top: "50%",
    right: "50%",
    zIndex: 1000,
  },
  customInput: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed

      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },

  select: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px", // Adjust the border radius as needed
      backgroundColor: "#ebebeb",
      "& fieldset": {
        border: "none", // Remove the default border
      },
    },
  },
}));

const EMOMExercisesUI = ({
  addArray,
  setaddArray,
  setModalChangeExe,
  isHistory,
  memberId,
  mainindex,
  isWorkoutCompleted,
  setIsWorkoutCompleted,
  memberRewardData,
  practiceType,
  handleSubmit,
}) => {
  const { state } = useLocation();
  const myInputRef = useRef(null);
  const [exerInfo, setExsrInfo] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenForSuperset, setModalOpenForSuperset] = useState(false);
  const [historyModalOpen, setHistoryModalOpen] = useState(false);
  const [historyModalOpenSuperset, setHistoryModalOpenSuperset] =
    useState(false);
  const [congratulation, setCongratulation] = useState(false);

  const [openSingleExerciseInfo, setOpenSingleExerciseInfo] = useState(false);
  const [singleExerciseInfo, setSingleExerciseInfo] = useState("");
  const [historyModalData, setHistoryModalData] = useState([]);
  const [superAlterIndex, setSuperAlterIndex] = useState("");
  const [alterChanges, setAlterChanges] = useState("");
  const [toAlterChanges, setToAlterChanges] = useState("");
  const [openExerciseAlter, setOpenExerciseAlter] = useState(false);
  const [openSuperSetAlter, setOpenSuperSetAlter] = useState(false);
  const [openExerciseAlert, setOpenExerciseAlert] = useState(false);

  const [alterIndex, setalterIndex] = useState();
  const [intervalTime, setIntervalTime] = useState(0);
  const [intervalSeconds, setIntervalSeconds] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedRound, setSelectedRound] = useState(1);
  const [isReaload, setIsReaload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [detailsData, setDetailsData] = useState("");
  const [exerIndex, setExerIndex] = useState("");
  const [outerIndex, setOuterIndex] = useState("");

  const [openDetails, setOpenDetails] = useState(false);

  const [isHovered, setIsHovered] = useState("");
  const [updatedIndexesArray, setUpdatedIndexesArray] = useState([]);
  const [isExerciseForSuperSet, setIsExerciseForSuperSet] = useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const classes = useStyles();
  const handleMinutesChange = (e) => {
    const minutes = parseInt(e.target.value) || 0;
    const seconds = addArray[mainindex].time_interval % 60;
    updateIntervalTime(minutes, seconds);
  };

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  const maxLength = 250;

  const handleSecondsChange = (e) => {
    let seconds = parseInt(e.target.value) || 0;
    seconds = Math.min(Math.max(seconds, 0), 59); // Ensure the value is between 0 and 60
    const minutes = Math.floor(addArray[mainindex].time_interval / 60);
    updateIntervalTime(minutes, seconds);
  };

  // Function to update intervalTime state
  const updateIntervalTime = (minutes, seconds) => {
    const totalSeconds = minutes * 60 + seconds;
    setIntervalTime(totalSeconds);
    const list = [...addArray];

    list[mainindex]["time_interval"] = totalSeconds;

    setaddArray(list);
  };
  const formatWithLeadingZeros = (value) => {
    return value < 10 ? `0${value}` : `${value}`;
  };
  const handleMouseEnter = (setIndex, exerciseIndex) => {
    setIsHovered(setIndex);
    setIsHoveredExercise(exerciseIndex);
  };
  const timeoutRef = useRef(null);

  useEffect(() => {
    // Cleanup function to clear the timeout on component unmount
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);
  const handleMouseLeave = () => {
    // Introduce a delay of 100 milliseconds before updating state on hover out
    const delay = 0;
    timeoutRef.current = setTimeout(() => {
      setIsHovered("");
    }, delay);
  };

  const handleAdd = (i) => {
    let list = addArray;

    list.splice(i + 1, 0, {
      exercise: "",
      set: [
        {
          time: "",
          weight: "",
          weightType: "kg",
          heightType: "cm",
          height: "",
          distance: "",
          distanceType: "m",
          timeType: "sec",
          restType: "sec",
          reps: "",
          maxReps: "",
          rest: "",
          tempo: "",
        },
      ],
      parameters: ["Weight", "Reps", "Rest"],
      time_interval: 60,
      rounds: 10,
      type: "general",
      emomSets: [[]],
      description: "",
      superSet: [],
      superset_sets: "",
    });

    setaddArray([...list]);
    setModalChangeExe(true);
  };
  const handleChangeComplete = (e, index) => {
    const { checked } = e.target;
    // if (original[index]["is_completed"]) {
    //   return;
    // }
    const list = [...addArray];
    if (!checked && isWorkoutCompleted) {
      setExerIndex(index);
      setOpenExerciseAlert(true);
      return;
    }
    list[index]["is_completed"] = checked;

    setModalChangeExe(true);
    setaddArray(list);
    if (checked) setCongratulation(true);
  };
  const handleAgreeInExerciseSet = () => {
    const list = [...addArray];
    list[exerIndex]["is_completed"] = false;
    setIsWorkoutCompleted(false);
    setModalChangeExe(true);
    setOpenExerciseAlert(false);
    setaddArray(list);
  };
  const handleAddInterval = (e) => {
    e.preventDefault();
    let list = addArray;
    list[mainindex].emomSets.push([]);
    setaddArray([...list]);
    setModalChangeExe(true);
  };
  const handleAddMoment = (outerIndex, inerIndex) => {
    let list = addArray;
    const lastIndex = list[mainindex].emomSets[outerIndex].length;
    list[mainindex].emomSets[outerIndex].splice(lastIndex, 0, {
      exercise: "",
      set: [
        {
          time: "",
          weight: "",
          weightType: "kg",
          heightType: "cm",
          height: "",
          distance: "",
          distanceType: "m",
          timeType: "sec",
          restType: "sec",
          reps: "",
          maxReps: "",
          rest: "",
          tempo: "",
        },
      ],
      parameters: ["Weight", "Reps", "Rest"],
      description: "",
      superSet: [],
      superset_sets: "",
    });

    setaddArray([...list]);
    setModalChangeExe(true);
  };
  const handleAddSet = (mainindex, index) => {
    let list = addArray;
    const data = { ...list[mainindex]["set"][index] };
    list[mainindex]["set"].splice(index + 1, 0, data);
    // ======= just add new set without values
    // list[mainindex]["set"].splice(index + 1, 0, {
    //   time: "",
    //   weight: "",

    //   distance: "",
    //   distanceType: "m",
    //   timeType: "sec",
    //   restType: "sec",
    //   reps: "",
    //   rest: "",
    // });

    setaddArray([...list]);
    setModalChangeExe(true);
  };

  const handleDeleteInterval = (mainindex, index) => {
    let list = addArray;

    list[mainindex]["emomSets"].splice(index, 1);

    setaddArray([...list]);
    setModalChangeExe(true);
  };
  const handleDeleteIntervalExercise = (index, exerIndex) => {
    let list = addArray;

    list[mainindex]["emomSets"][index].splice(exerIndex, 1);

    setaddArray([...list]);
    setModalChangeExe(true);
  };
  const handleDeleteExer = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);

    setaddArray(delValue);
    setModalChangeExe(true);
  };

  const removeSuperSet = (mainIndex, index) => {
    //  const { name, value } = e.target;
    const list = [...addArray];
    let firstPart = list[mainIndex]["superSet"].slice(0, index + 1);
    let secondPart = list[mainIndex]["superSet"].slice(index + 1);
    console.log(firstPart, secondPart, "dlfkjksdhfjas");
    let firstObject = { ...firstPart[0] };
    if (index > 0) {
      firstObject.superSet = firstPart;
      firstObject.superset_sets = list[mainIndex].superset_sets;
      console.log(firstObject, "fdjhsjkdfjkajds1", list[mainIndex]);
      // superset_sets;
    }

    let secondObject = { ...secondPart[0] };
    if (secondPart.length > 1) {
      secondObject.superSet = secondPart;
      secondObject.superset_sets = list[mainIndex].superset_sets;
      console.log(firstObject, "fdjhsjkdfjkajds2", list[mainIndex]);
    }

    let list1 = addArray;
    list1.splice(mainIndex + 1, 0, secondObject);
    list1.splice(mainIndex + 1, 0, firstObject);

    setaddArray([...list1]);
    setModalChangeExe(true);
    handleDeleteExer(mainIndex);
  };

  const handleAgreeSuperSet = (mainindex) => {
    setSuperAlterIndex(mainindex);

    setAlterChanges(
      addArray[mainindex + 1].superSet.length
        ? addArray[mainindex + 1].superset_sets
        : addArray[mainindex + 1].set.length
    );

    setToAlterChanges(
      addArray[mainindex].superSet.length
        ? addArray[mainindex].superset_sets
        : addArray[mainindex].set.length
    );

    const first = addArray[mainindex].superSet.length
      ? addArray[mainindex].superset_sets
      : addArray[mainindex].set.length;
    const second = addArray[mainindex + 1].superSet.length
      ? addArray[mainindex + 1].superset_sets
      : addArray[mainindex + 1].set.length;

    if (first == second) {
      addInSuperSet(mainindex);
      console.log(alterChanges, toAlterChanges, "dfjkhakjsdhfa");
    } else setOpenSuperSetAlter(true);
  };
  const handleAgreeExercise = (mainindex) => {
    setalterIndex(mainindex);

    setAlterChanges(
      addArray[mainindex + 1].superSet.length
        ? addArray[mainindex + 1].superset_sets
        : addArray[mainindex + 1].set.length
    );

    setToAlterChanges(
      addArray[mainindex].superSet.length
        ? addArray[mainindex].superSet.length
        : addArray[mainindex].set.length
    );

    const first = addArray[mainindex].superSet.length
      ? addArray[mainindex].superSet.length
      : addArray[mainindex].set.length;
    const second = addArray[mainindex + 1].superSet.length
      ? addArray[mainindex + 1].superset_sets
      : addArray[mainindex + 1].set.length;

    if (first == second) {
      addSuperSet(mainindex);
    } else {
      setOpenExerciseAlter(true);
    }
  };
  const addAgreeSuperSet = (index) => {
    //  const { name, value } = e.target;

    let current = { ...addArray[alterIndex] };
    current.superSet = [];
    let array = [current];

    if (
      addArray[alterIndex + 1]?.superSet &&
      addArray[alterIndex + 1].superSet.length > 0
    ) {
      addArray[alterIndex + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[alterIndex + 1]);
    }

    const list = [...addArray];
    list[alterIndex]["superSet"] = array;

    setaddArray(list);
    setModalChangeExe(true);

    let e = {
      target: {
        name: "superset_sets",
        value: addArray[alterIndex].set.length,
      },
    };
    handleChangeSuperSetCount(e, alterIndex);
    handleDelete(alterIndex + 1);
    setOpenExerciseAlter(false);
  };
  const addSuperSet = (index) => {
    //  const { name, value } = e.target;

    console.log(index, "dsjkhdkfgs", alterIndex);
    let current = { ...addArray[index] };
    current.superSet = [];
    let array = [current];

    if (
      addArray[index + 1]?.superSet &&
      addArray[index + 1].superSet.length > 0
    ) {
      addArray[index + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[index + 1]);
    }

    const list = [...addArray];
    list[index]["superSet"] = array;

    setaddArray(list);
    setModalChangeExe(true);

    let e = {
      target: {
        name: "superset_sets",
        value: addArray[index].set.length,
      },
    };
    handleChangeSuperSetCount(e, index);
    handleDelete(index + 1);
    setOpenExerciseAlter(false);
  };
  const addInSuperSet = (index) => {
    let array = addArray[index].superSet;
    if (addArray[index + 1].superSet.length > 0) {
      addArray[index + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[index + 1]);
    }
    const list = [...addArray];
    list[index]["superSet"] = array;

    setaddArray(list);
    setModalChangeExe(true);
    let e = {
      target: {
        name: "superset_sets",
        value: list[index].superset_sets,
      },
    };
    handleChangeSuperSetCount(e, index);
    handleDelete(index + 1);
    setOpenSuperSetAlter(false);
  };
  const addAgreeInSuperSet = () => {
    let array = addArray[superAlterIndex].superSet;
    if (addArray[superAlterIndex + 1].superSet.length > 0) {
      addArray[superAlterIndex + 1].superSet.map((item) => {
        array.push(item);
      });
    } else {
      array.push(addArray[superAlterIndex + 1]);
    }
    const list = [...addArray];
    list[superAlterIndex]["superSet"] = array;

    setaddArray(list);
    setModalChangeExe(true);
    let e = {
      target: {
        name: "superset_sets",
        value: list[superAlterIndex].superset_sets,
      },
    };
    handleChangeSuperSetCount(e, superAlterIndex);
    handleDelete(superAlterIndex + 1);
    setOpenSuperSetAlter(false);
  };
  const handleDelete = (i) => {
    const delValue = [...addArray];
    delValue.splice(i, 1);

    setaddArray(delValue);
    setModalChangeExe(true);
  };

  const handleArrow = (value, index) => {
    //  const { name, value } = e.target;

    const list = [...addArray];
    list[index]["open"] = value;

    setaddArray(list);
  };

  const handleArrowSuperSet = (value, index, exerIndex) => {
    //  const { name, value } = e.target;

    const list = [...addArray];
    list[mainindex]["emomSets"][index][exerIndex]["open"] = value;
    // list[mainindex]["superSet"][exerIndex]["open"] = value;

    setaddArray(list);
  };

  const handleChange = (e, index, index1) => {
    const { name, value } = e.target;

    const list = [...addArray];

    list[index]["set"][index1][name] = value;

    setaddArray(list);
    setModalChangeExe(true);
  };
  const handleChangeTempo = (e, index, index1) => {
    const { name, value } = e.target;
    if (value.length <= 4) {
      const list = [...addArray];

      list[index]["set"][index1][name] = value;

      setaddArray(list);
      setModalChangeExe(true);
    }
  };
  const handleChangeSuperSetDes = (e, index, index1) => {
    const { name, value } = e.target;
    const list = [...addArray];
    list[index]["superSet"][index1][name] = value;

    setaddArray(list);
    setModalChangeExe(true);
  };

  const handleChangeDes = (e, index) => {
    const { name, value } = e.target;

    const list = [...addArray];
    list[index][name] = value;

    setaddArray(list);
    setModalChangeExe(true);
  };

  const handleChangeEmomSet = (e, index, exerIndex, setIndex) => {
    const { name, value } = e.target;

    const list = [...addArray];
    list[mainindex]["emomSets"][index][exerIndex]["set"][setIndex][name] =
      value;

    setaddArray(list);
    console.log(addArray, "dkjfhkjsjkdkfjaj");
    setModalChangeExe(true);
  };
  const handleChangeEmomSetTempo = (e, index, exerIndex, setIndex) => {
    const { name, value } = e.target;
    if (value.length <= 4) {
      const list = [...addArray];
      list[mainindex]["emomSets"][index][exerIndex]["set"][setIndex][name] =
        value;
      setaddArray(list);
      setModalChangeExe(true);
    }
  };
  const handleAddSetCount = (index) => {
    const list = [...addArray];
    const name = "rounds";
    const value = parseInt(list[index].rounds) + 1;
    // console.log(list[index].superset_sets, "ksdfjhkjsdnfkjs");
    // Convert empty string to 1, otherwise parse the integer
    const targetValue = value === "" ? 1 : Math.max(1, parseInt(value, 10));

    list[index][name] = targetValue;

    // Iterate over superSet array and update set array
    // list[index]?.superSet.forEach((superSetItem) => {
    //   const currentSetLength = superSetItem.set.length;

    //   const data = { ...superSetItem.set[currentSetLength - 1] };
    //   if (currentSetLength < targetValue) {
    //     const objectsToAdd = targetValue - currentSetLength;

    //     for (let i = 0; i < objectsToAdd; i++) {
    //       superSetItem.set.push(data);
    //     }
    //   } else if (currentSetLength > targetValue) {
    //     // If the new count is less than the current count, remove excess objects
    //     superSetItem.set.splice(targetValue);
    //   }
    // });

    setaddArray(list);
    setModalChangeExe(true);
  };
  const handleRearageSetCount = (index, count) => {
    const list = [...addArray];
    const name = "superset_sets";
    const value = count;
    // console.log(list[index].superset_sets, "ksdfjhkjsdnfkjs");
    // Convert empty string to 1, otherwise parse the integer
    const targetValue = value === "" ? 1 : Math.max(1, parseInt(value, 10));

    list[index][name] = targetValue;

    // Iterate over superSet array and update set array
    list[index]?.superSet.forEach((superSetItem) => {
      const currentSetLength = superSetItem.set.length;

      if (currentSetLength < targetValue) {
        const objectsToAdd = targetValue - currentSetLength;

        for (let i = 0; i < objectsToAdd; i++) {
          superSetItem.set.push({
            time: "",
            weight: "",
            weightType: "kg",
            heightType: "cm",
            height: "",
            distance: "",
            distanceType: "m",
            timeType: "sec",
            restType: "sec",
            reps: "",
            maxReps: "",
            rest: "",
          });
        }
      } else if (currentSetLength > targetValue) {
        // If the new count is less than the current count, remove excess objects
        superSetItem.set.splice(targetValue);
      }
    });

    setaddArray(list);
    setModalChangeExe(true);
    setHistoryModalOpenSuperset(false);
  };
  const handleMinusSetCount = (index) => {
    // console.log(addArray[mainindex], "fjksdkjfaj");
    const list = [...addArray];
    const name = "rounds";
    const value = parseInt(list[index].rounds) - 1;
    // console.log(list[index].superset_sets, "ksdfjhkjsdnfkjs");
    // Convert empty string to 1, otherwise parse the integer
    const targetValue = value === "" ? 1 : Math.max(1, parseInt(value, 10));

    list[index][name] = targetValue;

    // Iterate over superSet array and update set array
    // list[index]?.superSet.forEach((superSetItem) => {
    //   const currentSetLength = superSetItem.set.length;

    //   if (currentSetLength < targetValue) {
    //     const objectsToAdd = targetValue - currentSetLength;

    //     for (let i = 0; i < objectsToAdd; i++) {
    //       superSetItem.set.push({
    //         time: "",
    //         weight: "",
    //         distance: "",
    //         distanceType: "m",
    //         timeType: "sec",
    //         restType: "sec",
    //         reps: "",
    //         rest: "",
    //       });
    //     }
    //   } else if (currentSetLength > targetValue) {
    //     // If the new count is less than the current count, remove excess objects
    //     superSetItem.set.splice(targetValue);
    //   }
    // });

    setaddArray(list);
    setModalChangeExe(true);
  };
  const getRounds = (count) => {
    const rounds = [];

    for (let i = 1; i <= count; i++) {
      rounds.push(
        <div
          onClick={() => {
            setSelectedRound(i);
          }}
          className={
            selectedRound == i
              ? "emom-round-count-box me-2 mt-2 "
              : "emom-round-count-box-disable me-2 mt-2"
          }
        >
          <div
            className={
              selectedRound == i
                ? "emom-round-count"
                : "emom-round-count-disable"
            }
          >
            {i}
          </div>
        </div>
      );
    }

    return rounds;
  };

  const getMinutesForEmom = () => {
    // console.log(addArray[mainindex], "fjksdkjfaj");
    let min = 10;
    let sec = 0;
    const list = [...addArray];
    const rounds = list[mainindex].rounds;
    const moments = list[mainindex].emomSets.length;
    const interval = list[mainindex].time_interval;
    min = formatWithLeadingZeros(
      Math.floor((rounds * moments * interval) / 60)
    );
    sec = formatWithLeadingZeros((rounds * moments * interval) % 60);
    // console.log(
    //   rounds * moments * interval,
    //   "jdkjfasd",
    //   (rounds * moments * interval) % 60,
    //   (rounds * moments * interval) / 60
    // );

    return `${
      Math.floor(interval / 60) +
      " " +
      ":" +
      formatWithLeadingZeros(interval % 60) +
      " " +
      "minute" +
      " " +
      "for" +
      " " +
      min +
      ":" +
      sec
    }`;
  };
  const getMinutesForInterval = (index) => {
    let min = 10;
    let sec = 0;
    const list = [...addArray];
    const rounds = list[mainindex].rounds;
    const moments = list[mainindex].emomSets.length;
    const interval = list[mainindex].time_interval;

    if (interval <= 60) {
      return index + 1;
    }
    if (interval % 60 == 0) {
      return `${
        (interval / 60) * index +
        1 +
        " – " +
        ((interval / 60) * index + interval / 60)
      }`;
    }
    let startInterval = interval * index;
    let endtInterval = interval * index + interval;
    let startintervalText =
      interval * index
        ? Math.floor(startInterval / 60) +
          " " +
          ":" +
          formatWithLeadingZeros(startInterval % 60)
        : 0;
    let endIntervalText =
      Math.floor(endtInterval / 60) +
      " " +
      ":" +
      formatWithLeadingZeros(endtInterval % 60);

    return `${startintervalText + " – " + endIntervalText}`;
  };
  // const getMinutesForInterval = (index, selectedIndex) => {
  //   const list = [...addArray];
  //   const interval = list[mainindex].time_interval;
  //   console.log(selectedIndex, "dsjfjkhkajs", index);
  //   if (interval <= 60) {
  //     return index + selectedIndex;
  //   }

  //   if (interval % 60 === 0) {
  //     const start = (interval / 60) * index + 1 + selectedIndex;
  //     const end = (interval / 60) * index + interval / 60 + selectedIndex;
  //     return `${start} – ${end}`;
  //   }

  //   const startInterval = interval * index + selectedIndex;
  //   const endInterval = interval * (index + 1) + selectedIndex;
  //   const startIntervalText =
  //     Math.floor(startInterval / 60) +
  //     " " +
  //     ":" +
  //     formatWithLeadingZeros(startInterval % 60);

  //   const endIntervalText =
  //     Math.floor(endInterval / 60) +
  //     " " +
  //     ":" +
  //     formatWithLeadingZeros(endInterval % 60);

  //   return `${startIntervalText} – ${endIntervalText}`;
  // };
  useMemo(() => {
    const arraylength = addArray[mainindex].emomSets.length;
    const lastIndex = parseInt(selectedRound) * arraylength;
    const startingIndex = lastIndex - (arraylength - 1);
    let finalArray = [];
    for (let index = startingIndex; index <= lastIndex; index++) {
      finalArray.push(index - 1);
    }
    setUpdatedIndexesArray(finalArray);
  }, [selectedRound]);
  // const getIndexForInterval = () => {};
  const handleChangeSuperSetCount = (e, index) => {
    const { name, value } = e.target;
    const list = [...addArray];

    // Convert empty string to 1, otherwise parse the integer
    const targetValue = value === "" ? 1 : Math.max(1, parseInt(value, 10));

    list[index][name] = targetValue;

    // Iterate over superSet array and update set array
    list[index]?.superSet.forEach((superSetItem) => {
      const currentSetLength = superSetItem.set.length;

      if (currentSetLength < targetValue) {
        const objectsToAdd = targetValue - currentSetLength;

        for (let i = 0; i < objectsToAdd; i++) {
          superSetItem.set.push({
            time: "",
            weight: "",
            weightType: "kg",
            heightType: "cm",
            height: "",
            distance: "",
            distanceType: "m",
            reps: "",
            maxReps: "",
            rest: "",
          });
        }
      } else if (currentSetLength > targetValue) {
        // If the new count is less than the current count, remove excess objects
        superSetItem.set.splice(targetValue);
      }
    });

    setaddArray(list);
    setModalChangeExe(true);
  };

  const handleChangeUp = (value) => {
    if (value == 0) {
      enqueueSnackbar("You are already on Top !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value - 1, 0, reorderedItem);

    setaddArray(items);
    setModalChangeExe(true);
  };

  const handleExerciseList = (value) => {
    setIsExerciseForSuperSet(false);
    setDetailsData(value);
    setOpenDetails(true);
  };
  const handleSupersetExerciseList = (mainIndex, exerInde) => {
    setDetailsData(mainIndex);
    setExerIndex(exerInde);
    setIsExerciseForSuperSet(true);
  };
  const handleSelectExercise = (value) => {
    const list = [...addArray];
    if (list[detailsData]["exercise"]._id == value._id) {
      return;
    }
    list[detailsData]["exercise"] = value;
    list[detailsData]["description"] = value?.instructions;

    setaddArray(list);
    setModalChangeExe(false);
    // if (isHistory) handleHistory(value, detailsData);
  };
  const handleCopyExercise = (value) => {
    const list = [...addArray];
    list[detailsData]["set"] = value.set;
    list[detailsData]["parameters"] = value?.parameters;
    setaddArray(list);
    setModalChangeExe(true);
    setHistoryModalOpen(false);
  };
  const handleCopySupersetExercise = (value) => {
    const list = [...addArray];
    console.log(list[detailsData], "dskjfjkdshafkja");
    list[detailsData]["superSet"][exerIndex]["set"] = value.set;
    list[detailsData]["superSet"][exerIndex]["parameters"] = value?.parameters;
    setaddArray(list);
    setModalChangeExe(true);

    handleRearageSetCount(detailsData, list[detailsData].superset_sets);
  };
  const handleSelectSupersetExercise = (value) => {
    const list = [...addArray];

    // return;

    list[mainindex]["emomSets"][detailsData][exerIndex]["exercise"] = value;
    list[mainindex]["emomSets"][detailsData][exerIndex]["description"] =
      value?.instructions;
    setaddArray(list);
    setModalChangeExe(true);

    // if (isHistory) handleHistorySuperset(value, detailsData, exerIndex);
  };

  const handleChangeDown = (value) => {
    if (value >= addArray.length - 1) {
      enqueueSnackbar("You are already on Bottom !", {
        variant: "info",
      });
      return;
    }
    const items = Array.from(addArray);
    const [reorderedItem] = items.splice(value, 1);
    items.splice(value + 1, 0, reorderedItem);

    setaddArray(items);
    setModalChangeExe(true);
  };

  const handleParameters = (value) => {
    setModalOpen(true);
    setModalOpenForSuperset(false);
    setOuterIndex(value);
    setExsrInfo(addArray[value].parameters);
  };
  const handleHistoryOpen = async (value, index) => {
    setDetailsData(index);

    // setHistoryModalData(value.history);

    setHistoryModalOpen(true);
  };
  const handleHistory = async (value, index) => {
    setDetailsData(index);
    let postData = {
      exercise: value?._id,
      member: memberId,
    };

    try {
      let result = await programme_workout_exercise_history_list_api(postData);
      if (result.code == 200) {
        if (!result.exercise_history.length > 0) {
          const list = [...addArray];
          delete list[index]["history"];
          setaddArray(list);

          return;
        }
        let givenExercise = result.exercise_history.map((item) => {
          return {
            ...item,
            date: item?.date,
            exercise: item?.exercise_info,
            set: item?.sets.map((item1) => {
              return {
                time: item1?.duration,
                mhr: item1?.mhr,
                weight: item1?.weight,
                weightType: item1?.weight_unit,
                reps: item1?.reps,
                maxReps: item1?.max_reps,
                rest: item1?.rest,
                distance: item1?.distance,
                distanceType: item1?.distance_unit,
                timeType: item1?.duration_unit,
                restType: item1?.rest_unit,
                tempo: item1?.tempo,
                vdot: item1?.vdot ? item1?.vdot : null,
                race_type: item1?.race_type ? item1?.race_type : "c",
                pace_type: item1?.pace_type ? item1?.pace_type : "c_km",
                pace: item1?.pace_low ? item1?.pace_low : 0,
                paceUp: item1?.pace_up ? item1?.pace_up : 423,
                optionsType:
                  item1?.pace_options && item1?.pace_options.length > 0
                    ? item1?.pace_options
                    : [
                        { title: "KM Pace", key: "c_km" },
                        { title: "MILE Pace", key: "c_mi" },
                      ],
              };
            }),
            parameters: item?.parameters,
            open: true,
          };
        });

        setHistoryModalData(givenExercise);

        // setHistoryModalOpen(true);
        const list = [...addArray];
        list[index]["history"] = givenExercise;
        setaddArray(list);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  const handleHistorySupersetOpen = async (value, mainIndex, exerInde) => {
    setDetailsData(mainIndex);
    setExerIndex(exerInde);
    // setHistoryModalData(value.history);
    handleHistorySuperset(value.exercise, mainIndex, exerInde);
  };
  const handleHistorySuperset = async (value, mainIndex, exerInde) => {
    setDetailsData(mainIndex);
    setExerIndex(exerInde);
    let postData = {
      exercise: value?._id,
      member: memberId,
    };
    setHistoryModalData([]);
    setIsLoading(true);
    try {
      let result = await programme_workout_exercise_history_list_api(postData);
      if (result.code == 200) {
        if (!result.exercise_history.length > 0) {
          const list = [...addArray];
          delete list[mainindex]["emomSets"][detailsData][exerIndex]["history"];
          // delete list[mainIndex]["superSet"][exerInde]["history"];
          setaddArray(list);

          return;
        }
        let givenExercise = result.exercise_history.map((item) => {
          return {
            ...item,
            date: item?.date,
            exercise: item?.exercise_info,
            set: item?.sets.map((item1) => {
              return {
                time: item1?.duration,
                mhr: item1?.mhr,
                weight: item1?.weight,
                weightType: item1?.weight_unit,
                reps: item1?.reps,
                maxReps: item1?.max_reps,
                rest: item1?.rest,
                distance: item1?.distance,
                distanceType: item1?.distance_unit,
                timeType: item1?.duration_unit,
                restType: item1?.rest_unit,
                tempo: item1?.tempo,
                vdot: item1?.vdot ? item1?.vdot : null,
                race_type: item1?.race_type ? item1?.race_type : "c",
                pace_type: item1?.pace_type ? item1?.pace_type : "c_km",
                pace: item1?.pace_low ? item1?.pace_low : 0,
                paceUp: item1?.pace_up ? item1?.pace_up : 423,
                optionsType:
                  item1?.pace_options && item1?.pace_options.length > 0
                    ? item1?.pace_options
                    : [
                        { title: "KM Pace", key: "c_km" },
                        { title: "MILE Pace", key: "c_mi" },
                      ],
              };
            }),
            parameters: item?.parameters,
            open: true,
          };
        });
        console.log(givenExercise, "kjdshakfjsdfhaj");
        setHistoryModalData(givenExercise);
        // setIsLoading(false);
        // setHistoryModalOpenSuperset(true);
        const list = [...addArray];
        list[mainindex]["emomSets"][detailsData][exerIndex]["history"] =
          givenExercise;
        setaddArray(list);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
      setHistoryModalOpenSuperset(true);
    }
  };
  const handleParametersSuperset = (main, inr) => {
    setModalOpen(true);
    setModalOpenForSuperset(true);
    setOuterIndex(main);
    setExerIndex(inr);
    setExsrInfo(addArray[mainindex].emomSets[main][inr].parameters);
  };
  const handleChangeParametersInsetFieldValueSuperSet = (
    value,
    outerIndex,
    exerIndex
  ) => {
    const items = Array.from(addArray);

    const sets = items[mainindex].emomSets[outerIndex][exerIndex].set.map(
      (item) => {
        return {
          ...item,
          time: items[mainindex].emomSets[outerIndex][
            exerIndex
          ].parameters.includes("Duration/Pace")
            ? item?.time
            : "",
          weight: items[mainindex].emomSets[outerIndex][
            exerIndex
          ].parameters.includes("Weight")
            ? item?.weight
            : "",
          weightType: item?.weightType,
          reps: items[mainindex].emomSets[outerIndex][
            exerIndex
          ].parameters.includes("Reps")
            ? item?.reps
            : "",
          maxReps: items[mainindex].emomSets[outerIndex][
            exerIndex
          ].parameters.includes("Max_Reps")
            ? item?.maxReps
            : "",
          rest: items[mainindex].emomSets[outerIndex][
            exerIndex
          ].parameters.includes("Rest")
            ? item?.rest
            : "",
          distance: items[mainindex].emomSets[outerIndex][
            exerIndex
          ].parameters.includes("Distance/Calories")
            ? item?.distance
            : "",
          distanceType: item?.distanceType,
          timeType: item?.timeType,
          restType: item?.restType,
          tempo: items[mainindex].emomSets[outerIndex][
            exerIndex
          ].parameters.includes("Tempo")
            ? item?.tempo
            : "",
        };
      }
    );
    // setExsrInfo(items[outerIndex].superSet[exerIndex].parameters);
    items[mainindex].emomSets[outerIndex][exerIndex].set = sets;
    setaddArray(items);
  };
  const handleChangeParametersInsetFieldValue = (value, outerIndex) => {
    const items = Array.from(addArray);
    const sets = items[outerIndex].set.map((item) => {
      return {
        time: items[outerIndex].parameters.includes("Duration/Pace")
          ? item?.time
          : "",
        weight: items[outerIndex].parameters.includes("Weight")
          ? item?.weight
          : "",
        weightType: item?.weightType,
        reps: items[outerIndex].parameters.includes("Reps") ? item?.reps : "",
        maxReps: items[outerIndex].parameters.includes("Max_Reps")
          ? item?.maxReps
          : "",
        rest: items[outerIndex].parameters.includes("Rest") ? item?.rest : "",
        distance: items[outerIndex].parameters.includes("Distance/Calories")
          ? item?.distance
          : "",
        distanceType: item?.distanceType,
        timeType: item?.timeType,
        restType: item?.restType,
        tempo: items[outerIndex].parameters.includes("Tempo")
          ? item?.tempo
          : "",
      };
    });
    console.log(items[outerIndex], "sdkfjklsdjfjlkakj");
    // setExsrInfo(items[outerIndex].parameters);
    items[outerIndex].set = sets;
    setaddArray(items);
  };
  const handleChangeParametersInset = (value) => {
    const items = Array.from(addArray);
    if (modalOpenForSuperset) {
      items[mainindex].emomSets[outerIndex][exerIndex].parameters = value;
      setExsrInfo(items[mainindex].emomSets[outerIndex][exerIndex].parameters);
      setaddArray(items);
      setModalChangeExe(true);
      handleChangeParametersInsetFieldValueSuperSet(
        value,
        outerIndex,
        exerIndex
      );
      return;
    }
    items[outerIndex].parameters = value;
    setExsrInfo(items[outerIndex].parameters);
    setaddArray(items);
    setModalChangeExe(true);
    handleChangeParametersInsetFieldValue(value, outerIndex);
  };

  const memueOptions = (value) => {
    const MENU_OPTIONS = [];
    MENU_OPTIONS.unshift(
      {
        label: "Move Up",
        icon: "mdi:arrow-up",
        handleClick: handleChangeUp,
        disabled: value == 0 ? true : false,
      },
      {
        label: "Move Down",
        icon: "ic:outline-arrow-downward",
        handleClick: handleChangeDown,
        disabled: value >= addArray.length - 1 ? true : false,
      }
      // {
      //   label: "Parameters",
      //   icon: "pajamas:labels",
      //   handleClick: handleParameters,
      // }
    );

    return MENU_OPTIONS;
  };
  const getPaceTextinPaces = (type) => {
    let result = "";
    if (type == "m") {
      result = "Time Cap";
    } else if (type == "mi") {
      result = "Pace Min/Mile";
    } else if (type == "km") {
      result = "Pace Min/Km";
    }
    return result;
  };
  const getKmhrForPace = (value, time, type) => {
    if (!value || !time) {
      return 0;
    }
    let result = 0;
    if (type == "m") {
      result = (value / time) * 3.6;
    } else if (type == "mi") {
      result = ((1 * 1000) / time) * 3.6 * 1.609;
    } else if (type == "km") {
      result = ((1 * 1000) / time) * 3.6;
    }
    return result.toFixed(2);
  };
  const getMihrForPace = (value, time, type) => {
    if (!value || !time) {
      return 0;
    }
    let result = 0;
    if (type == "m") {
      result = result = ((value / time) * 3.6) / 1.609;
    } else if (type == "mi") {
      result = ((1 * 1000) / time) * 3.6;
    } else if (type == "km") {
      result = (((1 * 1000) / time) * 3.6) / 1.609;
    }
    return result.toFixed(2);
  };
  const handleMinutesChangeSets = (e, index, exerIndex, setIndex) => {
    const { name, value } = e.target;

    const list = [...addArray];

    const minutes = parseInt(e.target.value) || 0;
    const seconds =
      list[mainindex]["emomSets"][index][exerIndex]["set"][setIndex][name] % 60;
    updateIntervalTimeSets(minutes, seconds, index, exerIndex, setIndex, name);
  };

  const handleSecondsChangeSets = (e, index, exerIndex, setIndex) => {
    const { name, value } = e.target;
    const list = [...addArray];

    let seconds = parseInt(e.target.value) || 0;
    seconds = Math.min(Math.max(seconds, 0), 59); // Ensure the value is between 0 and 60
    const minutes = Math.floor(
      list[mainindex]["emomSets"][index][exerIndex]["set"][setIndex][name] / 60
    );
    updateIntervalTimeSets(minutes, seconds, index, exerIndex, setIndex, name);
  };

  // Function to update intervalTime state
  const updateIntervalTimeSets = (
    minutes,
    seconds,
    index,
    exerIndex,
    setIndex,
    name
  ) => {
    const totalSeconds = minutes * 60 + seconds;
    const list = [...addArray];
    list[mainindex]["emomSets"][index][exerIndex]["set"][setIndex][name] =
      totalSeconds;
    setaddArray(list);
    setModalChangeExe(true);
  };
  const handleClickOpen = async (exercise) => {
    setSingleExerciseInfo(exercise);
    setOpenSingleExerciseInfo(true);
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (congratulation) {
        setCongratulation(false);
        handleSubmit(true); // Update the state after 3 seconds
      }
    }, 2500);

    return () => clearTimeout(timeout); // Clean up the timeout to avoid memory leaks
  }, [congratulation]);
  if (isReaload === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="my-2">
      {isLoading && (
        <CircularProgress className={classes.loading2} color="primary" />
      )}
      {congratulation && <CongratulationAnimation />}
      <ConfirmationWithDescription
        open={openSuperSetAlter}
        setOpen={setOpenSuperSetAlter}
        title={"Are you sure you want to take this action?"}
        descriotion={`You are trying to alternate an exercise with  ${toAlterChanges} sets with an exercise with ${alterChanges} sets. If you proceed, we’ll modify the exercise to have  ${toAlterChanges} sets.`}
        handleAgree={addAgreeInSuperSet}
      />
      <ConfirmationWithDescription
        open={openExerciseAlert}
        setOpen={setOpenExerciseAlert}
        title={
          // memberRewardData?.total_points - memberRewardData[practiceType] < 0
          //   ? "Opps!" :
          "Are you sure you want to take this action?"
        }
        descriotion={
          // memberRewardData?.total_points - memberRewardData[practiceType] < 0
          //   ? "You are not able to uncomplete because you have insufficient points/coins." :
          `If you uncheck, your points or coins allocated for this ${practiceType} will be deducted.`
        }
        isJustForOk={
          // memberRewardData?.total_points - memberRewardData[practiceType] < 0
          //   ? true :
          false
        }
        handleAgree={handleAgreeInExerciseSet}
      />
      <ToshowExercise
        open={openDetails}
        setOpen={setOpenDetails}
        handleChange={handleSelectExercise}
      />
      <ModalToShowAnExerciseInfo
        open={openSingleExerciseInfo}
        setOpen={setOpenSingleExerciseInfo}
        Id={singleExerciseInfo}
      />
      <ToshowExercise
        open={isExerciseForSuperSet}
        setOpen={setIsExerciseForSuperSet}
        handleChange={handleSelectSupersetExercise}
      />
      <ParameterModel
        open={modalOpen}
        setOpen={setModalOpen}
        data={exerInfo}
        handleChange={handleChangeParametersInset}
      />
      <ExerciseHistoryModel
        open={historyModalOpen}
        setOpen={setHistoryModalOpen}
        data={historyModalData}
        handleChange={setHistoryModalData}
        handleCopyExercise={handleCopyExercise}
      />
      <ExerciseHistoryModel
        open={historyModalOpenSuperset}
        setOpen={setHistoryModalOpenSuperset}
        data={historyModalData}
        handleChange={setHistoryModalData}
        handleCopyExercise={handleCopySupersetExercise}
      />
      <div className="mt-2 ">
        <div className="characters">
          {/* <form onSubmit={handleSubmit}> */}

          <form onSubmit={() => {}}>
            <div
              style={{ position: "relative" }}
              className="workout-Superset-card my-1"
            >
              <Box>
                <div className=" mb-3">
                  <div className="d-flex align-items-center justify-content-center  my-2">
                    <div className="text-center">
                      Every {getMinutesForEmom()} minutes{" "}
                      {addArray[mainindex].rounds} rounds.
                    </div>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ flexWrap: "wrap" }}
                  >
                    <div className="emom-round-title mt-2">Rounds: </div>
                    {getRounds(addArray[mainindex].rounds)}
                  </div>
                </div>

                {addArray[mainindex].emomSets.length > 0 &&
                  addArray[mainindex].emomSets.map((item, index) => {
                    return (
                      <div className=" my-2 ">
                        <div className="d-flex justify-content-between align-items-center ">
                          <div>
                            Minute{" "}
                            {getMinutesForInterval(updatedIndexesArray[index])}
                          </div>
                        </div>

                        {item.length > 0 &&
                          item.map((exerData, exerIndex) => {
                            return (
                              <div className="ms-4 mt-1">
                                <div
                                  className="d-flex workout-set-card  "
                                  style={{
                                    width: "100%",
                                    position: "relative",
                                    overflow: "hidden",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      position: "relative",
                                      width: "100%",
                                    }}
                                  >
                                    <div
                                      className="row  "
                                      onMouseEnter={() => handleMouseLeave()}
                                    >
                                      <div
                                        className={`col-sm-12 ${"col-md-6"} col-lg-6 d-flex align-items-center p-0 mt-2 mt-md-0 mt-lg-0 `}
                                      >
                                        <div className="exercise-label-box">
                                          <span className="outer-label">
                                            Exercise
                                          </span>
                                          <div className="d-flex align-items-center justify-content-between">
                                            <div
                                              className="exercise-label w-100"
                                              style={{ cursor: "default" }}
                                            >
                                              <div className="d-flex align-items-center">
                                                {exerData?.exercise?.image
                                                  ?.thumbnail_3 && (
                                                  <Avatar
                                                    sx={{ borderRadius: "5px" }}
                                                    alt="User Image"
                                                    src={
                                                      s3baseUrl +
                                                      exerData?.exercise?.image
                                                        ?.thumbnail_3
                                                    }
                                                  />
                                                )}
                                                <div className="label-text ms-1">
                                                  {exerData?.exercise?.title
                                                    ? exerData?.exercise?.title
                                                    : ""}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="exercise-action">
                                              <div style={{ fontSize: "16px" }}>
                                                {exerData?.exercise ? (
                                                  <div>
                                                    <InfoIcon
                                                      onClick={() =>
                                                        handleClickOpen(
                                                          exerData?.exercise
                                                            ?._id
                                                        )
                                                      }
                                                      className="d-block"
                                                      fontSize="14px"
                                                      sx={{
                                                        opacity: 0.5,
                                                        cursor: "pointer",
                                                      }}
                                                    />
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <Button
                                          sx={{ textTransform: "inherit" }}
                                          size="small"
                                          color="primary"
                                          variant="contained"
                                          className="d-flex align-items-center ms-2 small-contained-dialog-button"
                                          onClick={() => {
                                            handleArrowSuperSet(
                                              !exerData?.open ? true : false,
                                              index,
                                              exerIndex
                                            );
                                          }}
                                        >
                                          {!exerData?.open ? (
                                            <KeyboardArrowDownIcon
                                              className="bounce"
                                              sx={{
                                                cursor: "pointer",
                                                // opacity: 0.5,
                                                fontSize: "20px",
                                              }}
                                            />
                                          ) : (
                                            <KeyboardArrowUpIcon
                                              sx={{
                                                cursor: "pointer",
                                                // opacity: 0.5,
                                                fontSize: "20px",
                                              }}
                                            />
                                          )}

                                          <Typography
                                            variant="body2"
                                            sx={{
                                              // opacity: 0.6,
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            {` Sets ${1}`}
                                          </Typography>
                                        </Button>
                                      </div>

                                      <div
                                        className={`col-sm-12 ${"col-md-6"} col-lg-6 p-0 mt-2 mt-md-0 mt-lg-0 d-flex align-items-center justify-content-start `}
                                      >
                                        <div className="d-flex align-items-center mx-1">
                                          {/* <div
                                            className="d-flex align-items-center ms-3 set-parameters "
                                            onClick={() => {
                                              handleParametersSuperset(
                                                index,
                                                exerIndex
                                              );
                                            }}
                                          >
                                            <Icon
                                              icon="pajamas:labels"
                                              fontSize="10px"
                                            />
                                            <Typography
                                              variant="body2"
                                              sx={{ ml: 0.5 }}
                                            >
                                              {`Parameters`}
                                            </Typography>
                                          </div> */}
                                          {exerData?.exercise && (
                                            <Button
                                              sx={{ textTransform: "inherit" }}
                                              size="small"
                                              color="primary"
                                              variant="contained"
                                              className="d-flex align-items-center ms-2 small-contained-dialog-button"
                                              onClick={() => {
                                                handleHistorySupersetOpen(
                                                  exerData,
                                                  index,
                                                  exerIndex
                                                );
                                              }}
                                            >
                                              <Icon
                                                icon="fa-solid:history"
                                                fontSize="10px"
                                              />
                                              <Typography
                                                variant="body2"
                                                sx={{ ml: 0.5 }}
                                              >
                                                {`History`}
                                              </Typography>
                                            </Button>
                                          )}
                                        </div>
                                      </div>
                                    </div>

                                    {
                                      exerData?.open && (
                                        // exerData?.set?.map((item, setIndex) => (
                                        <div className="d-flex align-items-center my-3 workout-card-inset">
                                          <div className="row w-100">
                                            {exerData.parameters.includes(
                                              "Pace"
                                            ) && (
                                              <>
                                                <div
                                                  className={`col-12 col-md-3 col-lg-2 pe-0 py-2`}
                                                >
                                                  <FormControl fullWidth>
                                                    <InputLabel
                                                      className="set-Type-lable"
                                                      sx={{
                                                        fontSize: "14px",
                                                      }}
                                                      id="demo-simple-select-label"
                                                    >
                                                      Type
                                                    </InputLabel>

                                                    <Select
                                                      className="set-Type-value"
                                                      id="select-option"
                                                      variant="outlined"
                                                      size="small"
                                                      name="race_type"
                                                      readOnly
                                                      value={
                                                        exerData.set[
                                                          selectedRound - 1
                                                        ].race_type
                                                          ? exerData.set[
                                                              selectedRound - 1
                                                            ].race_type
                                                          : ""
                                                      }
                                                      label="Type"
                                                      inputProps={{
                                                        style: {
                                                          padding: 0,
                                                        },
                                                      }}
                                                      onChange={(e) => {
                                                        handleChangeEmomSet(
                                                          e,
                                                          index,
                                                          exerIndex,
                                                          setIndex
                                                        );
                                                      }}
                                                      sx={{
                                                        width: "100%",
                                                        fontSize: "13px",
                                                        "& .MuiOutlinedInput-input":
                                                          {
                                                            paddingLeft: 1,
                                                            paddingRight:
                                                              "0px !important",
                                                          },
                                                      }}
                                                    >
                                                      <MenuItem value="el">
                                                        Easy/Long
                                                      </MenuItem>
                                                      <MenuItem value="c">
                                                        Compromised
                                                      </MenuItem>

                                                      <MenuItem value="i">
                                                        Interval
                                                      </MenuItem>
                                                      <MenuItem value="r">
                                                        Repitition
                                                      </MenuItem>
                                                    </Select>
                                                  </FormControl>
                                                </div>
                                                <div
                                                  className={`col-12 col-md-3 col-lg-2 pe-0 py-2`}
                                                >
                                                  <TextField
                                                    sx={{
                                                      borderRadius: "5px",
                                                      paddingRight: 0,
                                                      width: "100%",
                                                    }}
                                                    size="small"
                                                    id="custom-input"
                                                    variant="outlined"
                                                    placeholder={"Distance"}
                                                    name="distance"
                                                    label="Distance"
                                                    value={
                                                      exerData.set[
                                                        selectedRound - 1
                                                      ].distance
                                                        ? exerData.set[
                                                            selectedRound - 1
                                                          ].distance
                                                        : ""
                                                    }
                                                    onChange={(e) => {
                                                      handleChangeEmomSet(
                                                        e,
                                                        index,
                                                        exerIndex,
                                                        setIndex
                                                      );
                                                    }}
                                                    type="number"
                                                    InputLabelProps={{
                                                      className:
                                                        "textfiels-input-label",
                                                      shrink: true,

                                                      style: {
                                                        fontSize: "14px",
                                                      },
                                                    }}
                                                    InputProps={{
                                                      className:
                                                        "textfiels-input-value",
                                                      style: {
                                                        fontSize: "13px",
                                                        paddingRight: 0,
                                                      },
                                                      readOnly: true,
                                                      endAdornment: (
                                                        <InputAdornment position="end">
                                                          <Select
                                                            id="select-option"
                                                            variant="outlined"
                                                            size="small"
                                                            name="distanceType"
                                                            value={
                                                              exerData.set[
                                                                selectedRound -
                                                                  1
                                                              ].distanceType
                                                                ? exerData.set[
                                                                    selectedRound -
                                                                      1
                                                                  ].distanceType
                                                                : ""
                                                            }
                                                            label="Weight Type"
                                                            onChange={(e) => {
                                                              handleChangeEmomSet(
                                                                e,
                                                                index,
                                                                exerIndex,
                                                                setIndex
                                                              );
                                                            }}
                                                            readOnly={true}
                                                            sx={{
                                                              "& legend": {
                                                                display: "none",
                                                              },
                                                              "& fieldset": {
                                                                top: 0,
                                                              },
                                                              fontSize: "13px",
                                                              border: "none",
                                                              "& fieldset": {
                                                                border: "none",
                                                              },
                                                            }}
                                                          >
                                                            <MenuItem value="m">
                                                              Meter
                                                            </MenuItem>
                                                            <MenuItem value="km">
                                                              KM
                                                            </MenuItem>
                                                            <MenuItem value="mi">
                                                              Mile
                                                            </MenuItem>
                                                          </Select>
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                  />
                                                </div>
                                                <div
                                                  className={`col-12 col-md-2 col-lg-2 pe-0 py-2`}
                                                >
                                                  <TextField
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    label={getPaceTextinPaces(
                                                      exerData.set[
                                                        selectedRound - 1
                                                      ].distanceType
                                                        ? exerData.set[
                                                            selectedRound - 1
                                                          ].distanceType
                                                        : ""
                                                    )}
                                                    InputLabelProps={{
                                                      className:
                                                        "textfiels-input-label",
                                                      shrink: true,
                                                      style: {
                                                        fontSize: "14px",
                                                      },
                                                    }}
                                                    InputProps={{
                                                      className:
                                                        "textfiels-input-value",
                                                      style: {
                                                        fontSize: "13px",
                                                        paddingLeft: "0px",
                                                        paddingRight: "0px",

                                                        justifyContent:
                                                          "center",
                                                      },
                                                      readOnly: true,
                                                      endAdornment: (
                                                        <InputAdornment
                                                          position="end"
                                                          style={{
                                                            display: "flex",
                                                            alignItems:
                                                              "center",
                                                          }}
                                                        >
                                                          <TextField
                                                            id="outlined-basic-minutes"
                                                            type="number"
                                                            size="small"
                                                            placeholder="Min"
                                                            name="pace"
                                                            value={formatWithLeadingZeros(
                                                              Math.floor(
                                                                exerData.set[
                                                                  selectedRound -
                                                                    1
                                                                ].pace
                                                                  ? exerData
                                                                      .set[
                                                                      selectedRound -
                                                                        1
                                                                    ].pace
                                                                  : 0 / 60
                                                              )
                                                            )}
                                                            onChange={(e) =>
                                                              handleMinutesChangeSets(
                                                                e,
                                                                index,
                                                                exerIndex,
                                                                setIndex
                                                              )
                                                            }
                                                            InputProps={{
                                                              className:
                                                                "textfiels-input-value",
                                                              style: {
                                                                fontSize:
                                                                  "14px",
                                                                // width: "40px",
                                                              },
                                                              readOnly: true,
                                                            }}
                                                            sx={{
                                                              "& fieldset": {
                                                                border: "none",
                                                              },
                                                              // "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                                              //   {
                                                              //     padding: "0px !important",
                                                              //   },
                                                              "& input": {
                                                                textAlign:
                                                                  "center",
                                                                padding: "0px",
                                                              },
                                                              width: "100%",
                                                            }}
                                                          />
                                                          <div className="fs-6 fw-semibold ms-1 ">
                                                            :
                                                          </div>

                                                          <TextField
                                                            id="outlined-basic-seconds"
                                                            type="number"
                                                            size="small"
                                                            placeholder="Sec"
                                                            name="pace"
                                                            value={formatWithLeadingZeros(
                                                              exerData.set[
                                                                selectedRound -
                                                                  1
                                                              ].pace
                                                                ? exerData.set[
                                                                    selectedRound -
                                                                      1
                                                                  ].pace
                                                                : 0 % 60
                                                            )}
                                                            onChange={(e) =>
                                                              handleSecondsChangeSets(
                                                                e,
                                                                index,
                                                                exerIndex,
                                                                setIndex
                                                              )
                                                            }
                                                            InputProps={{
                                                              className:
                                                                "textfiels-input-value",
                                                              style: {
                                                                fontSize:
                                                                  "14px",
                                                                // width: "40px",
                                                              },
                                                              readOnly: true,
                                                            }}
                                                            sx={{
                                                              "& fieldset": {
                                                                border: "none",
                                                              },
                                                              // "& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input":
                                                              //   {
                                                              //     padding: "0px !important",
                                                              //   },
                                                              "& input": {
                                                                textAlign:
                                                                  "center",
                                                                padding: "0px",
                                                              },
                                                              width: "100%",
                                                            }}
                                                          />
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                    sx={{
                                                      borderRadius: "5px",
                                                      fontSize: "13px",
                                                      "& .css-e9crry-MuiInputBase-input-MuiOutlinedInput-input":
                                                        {
                                                          width:
                                                            "0px !important",
                                                          padding:
                                                            "9px 0px !important",
                                                        },
                                                      "& .css-1nuss9t": {
                                                        width: "0px !important",
                                                        padding:
                                                          "9px 0px !important",
                                                      },
                                                      // "& input": {
                                                      //   textAlign: "center",
                                                      //   padding: "0px",
                                                      // },
                                                      width: "100%",
                                                    }}
                                                  />
                                                </div>
                                                <div
                                                  style={{
                                                    position: "absolute",
                                                    bottom: "4px",
                                                    left: "5px",
                                                  }}
                                                >
                                                  <Typography
                                                    variant="subtitle2"
                                                    sx={{
                                                      opacity: 0.6,
                                                      fontWeight: "600",
                                                      fontSize: "12px",
                                                    }}
                                                  >{` @ Speed ${getKmhrForPace(
                                                    exerData.set[
                                                      selectedRound - 1
                                                    ].distance
                                                      ? exerData.set[
                                                          selectedRound - 1
                                                        ].distance
                                                      : 0,
                                                    exerData.set[
                                                      selectedRound - 1
                                                    ].pace
                                                      ? exerData.set[
                                                          selectedRound - 1
                                                        ].pace
                                                      : 0,
                                                    exerData.set[
                                                      selectedRound - 1
                                                    ].distanceType
                                                      ? exerData.set[
                                                          selectedRound - 1
                                                        ].distanceType
                                                      : 0
                                                  )} km/hr or ${getMihrForPace(
                                                    exerData.set[
                                                      selectedRound - 1
                                                    ].distance
                                                      ? exerData.set[
                                                          selectedRound - 1
                                                        ].distance
                                                      : 0,
                                                    exerData.set[
                                                      selectedRound - 1
                                                    ].pace
                                                      ? exerData.set[
                                                          selectedRound - 1
                                                        ].pace
                                                      : 0,
                                                    exerData.set[
                                                      selectedRound - 1
                                                    ].distanceType
                                                      ? exerData.set[
                                                          selectedRound - 1
                                                        ].distanceType
                                                      : 0
                                                  )} mi/hr`}</Typography>
                                                </div>
                                              </>
                                            )}
                                            {(exerData.parameters.includes(
                                              "Duration/Pace"
                                            ) ||
                                              exerData.parameters.includes(
                                                "Time"
                                              )) && (
                                              <div
                                                className={`col-12 col-md-3 col-lg-2 pe-0 py-2`}
                                              >
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="0"
                                                  type="number"
                                                  value={
                                                    exerData.set[
                                                      selectedRound - 1
                                                    ]?.time
                                                      ? exerData.set[
                                                          selectedRound - 1
                                                        ]?.time
                                                      : ""
                                                  }
                                                  name="time"
                                                  onChange={(e) => {
                                                    handleChangeEmomSet(
                                                      e,
                                                      index,
                                                      exerIndex,
                                                      selectedRound - 1
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  label="Duration/Pace"
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                      paddingRight: 0,
                                                    },

                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <Select
                                                          id="select-option"
                                                          variant="outlined"
                                                          size="small"
                                                          name="timeType"
                                                          value={
                                                            exerData.set[
                                                              selectedRound - 1
                                                            ]?.timeType
                                                          }
                                                          label="Weight Type"
                                                          inputProps={{
                                                            style: {
                                                              padding: 0,
                                                            },
                                                          }}
                                                          onChange={(e) => {
                                                            handleChangeEmomSet(
                                                              e,
                                                              index,
                                                              exerIndex,
                                                              selectedRound - 1
                                                            );
                                                          }}
                                                          sx={{
                                                            "& legend": {
                                                              display: "none",
                                                            },
                                                            "& fieldset": {
                                                              top: 0,
                                                            },
                                                            fontSize: "13px",
                                                            border: "none",
                                                            "& fieldset": {
                                                              border: "none",
                                                            },
                                                            "& .MuiOutlinedInput-input":
                                                              {
                                                                padding: 0,
                                                              },
                                                          }}
                                                        >
                                                          <MenuItem value="sec">
                                                            sec
                                                          </MenuItem>
                                                          <MenuItem value="min">
                                                            min
                                                          </MenuItem>
                                                        </Select>
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",
                                                    fontSize: "13px",
                                                    width: "100%",
                                                  }}
                                                />
                                              </div>
                                            )}

                                            {exerData.parameters.includes(
                                              "Reps"
                                            ) && (
                                              <div className="col-12 col-md-2 col-lg-1 pe-0 py-2">
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="Reps"
                                                  type="number"
                                                  label="Reps"
                                                  value={
                                                    exerData.set[
                                                      selectedRound - 1
                                                    ]?.reps
                                                      ? exerData.set[
                                                          selectedRound - 1
                                                        ]?.reps
                                                      : ""
                                                  }
                                                  name="reps"
                                                  onChange={(e) => {
                                                    handleChangeEmomSet(
                                                      e,
                                                      index,
                                                      exerIndex,
                                                      selectedRound - 1
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                    },
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",
                                                    // "& legend": { display: "none" },
                                                    // "& fieldset": { top: 0 },
                                                    fontSize: "13px",
                                                    // width: "13%",
                                                    width: "100%",
                                                  }}
                                                />
                                              </div>
                                            )}
                                            {exerData.parameters.includes(
                                              "Max_Reps"
                                            ) && (
                                              <div className="col-12 col-md-2 col-lg-1 pe-0 py-2">
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="Max Reps"
                                                  type="number"
                                                  label="Max Reps"
                                                  value={
                                                    exerData.set[
                                                      selectedRound - 1
                                                    ]?.maxReps
                                                      ? exerData.set[
                                                          selectedRound - 1
                                                        ]?.maxReps
                                                      : ""
                                                  }
                                                  name="maxReps"
                                                  onChange={(e) => {
                                                    handleChangeEmomSet(
                                                      e,
                                                      index,
                                                      exerIndex,
                                                      selectedRound - 1
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                    },
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",
                                                    // "& legend": { display: "none" },
                                                    // "& fieldset": { top: 0 },
                                                    fontSize: "13px",
                                                    // width: "13%",
                                                    width: "100%",
                                                  }}
                                                />
                                              </div>
                                            )}
                                            {exerData.parameters.includes(
                                              "Height"
                                            ) && (
                                              <div className="col-12 col-md-3 col-lg-2 pe-0 py-2">
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="0"
                                                  type="number"
                                                  value={
                                                    exerData.set[
                                                      selectedRound - 1
                                                    ]?.height
                                                      ? exerData.set[
                                                          selectedRound - 1
                                                        ]?.height
                                                      : ""
                                                  }
                                                  name="height"
                                                  onChange={(e) => {
                                                    handleChangeEmomSet(
                                                      e,
                                                      index,
                                                      exerIndex,
                                                      selectedRound - 1
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  label="Height"
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                      paddingRight: 0,
                                                    },
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <Select
                                                          id="select-option"
                                                          variant="outlined"
                                                          size="small"
                                                          name="heightType"
                                                          value={
                                                            exerData.set[
                                                              selectedRound - 1
                                                            ]?.heightType
                                                          }
                                                          label="Weight Type"
                                                          inputProps={{
                                                            style: {
                                                              padding: 0,
                                                            },
                                                          }}
                                                          onChange={(e) => {
                                                            handleChangeEmomSet(
                                                              e,
                                                              index,
                                                              exerIndex,
                                                              selectedRound - 1
                                                            );
                                                          }}
                                                          sx={{
                                                            "& legend": {
                                                              display: "none",
                                                            },
                                                            "& fieldset": {
                                                              top: 0,
                                                            },
                                                            fontSize: "13px",
                                                            border: "none",
                                                            "& fieldset": {
                                                              border: "none",
                                                            },
                                                            "& .MuiOutlinedInput-input":
                                                              {
                                                                padding: 0,
                                                              },
                                                          }}
                                                        >
                                                          <MenuItem value="cm">
                                                            Cm
                                                          </MenuItem>
                                                          <MenuItem value="ft">
                                                            Feet
                                                          </MenuItem>
                                                          <MenuItem value="inch">
                                                            Inch
                                                          </MenuItem>
                                                        </Select>
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",
                                                    fontSize: "13px",
                                                    width: "100%",
                                                  }}
                                                />
                                              </div>
                                            )}
                                            {exerData.parameters.includes(
                                              "Weight"
                                            ) && (
                                              <div className="col-12 col-md-4 col-lg-2 pe-0 py-2">
                                                <TextField
                                                  sx={{
                                                    borderRadius: "5px",
                                                    paddingRight: 0,
                                                    width: "100%",
                                                  }}
                                                  size="small"
                                                  id="custom-input"
                                                  variant="outlined"
                                                  placeholder="Weight"
                                                  name="weight"
                                                  label="Weight"
                                                  value={
                                                    exerData?.set[
                                                      selectedRound - 1
                                                    ]?.weight
                                                      ? exerData?.set[
                                                          selectedRound - 1
                                                        ]?.weight
                                                      : ""
                                                  }
                                                  onChange={(e) => {
                                                    handleChangeEmomSet(
                                                      e,
                                                      index,
                                                      exerIndex,
                                                      selectedRound - 1
                                                    );
                                                  }}
                                                  type="number"
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                      paddingRight: 0,
                                                    },
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <Select
                                                          id="select-option"
                                                          variant="outlined"
                                                          size="small"
                                                          name="weightType"
                                                          value={
                                                            exerData.set[
                                                              selectedRound - 1
                                                            ]?.weightType
                                                          }
                                                          label="Weight Type"
                                                          onChange={(e) => {
                                                            handleChangeEmomSet(
                                                              e,
                                                              index,
                                                              exerIndex,
                                                              selectedRound - 1
                                                            );
                                                          }}
                                                          sx={{
                                                            "& legend": {
                                                              display: "none",
                                                            },
                                                            "& fieldset": {
                                                              top: 0,
                                                            },
                                                            fontSize: "13px",
                                                            border: "none",
                                                            "& fieldset": {
                                                              border: "none",
                                                            },
                                                          }}
                                                        >
                                                          <MenuItem value="1rm">
                                                            % 1RM
                                                          </MenuItem>
                                                          <MenuItem value="body_weight">
                                                            % BW
                                                          </MenuItem>
                                                          <MenuItem value="kg">
                                                            KG
                                                          </MenuItem>
                                                          <MenuItem value="lb">
                                                            Pound
                                                          </MenuItem>
                                                          <MenuItem value="poods">
                                                            Poods
                                                          </MenuItem>
                                                        </Select>
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                              </div>
                                            )}
                                            {exerData.parameters.includes(
                                              "Distance/Calories"
                                            ) && (
                                              <div className="col-12 col-md-3 col-lg-2 pe-0 py-2">
                                                <TextField
                                                  sx={{
                                                    borderRadius: "5px",
                                                    paddingRight: 0,
                                                    width: "100%",
                                                  }}
                                                  size="small"
                                                  id="custom-input"
                                                  variant="outlined"
                                                  placeholder={
                                                    exerData.set[
                                                      selectedRound - 1
                                                    ].distanceType == "calories"
                                                      ? "Calories"
                                                      : "Distance"
                                                  }
                                                  name="distance"
                                                  label="Distance/Calories"
                                                  value={
                                                    exerData?.set[
                                                      selectedRound - 1
                                                    ]?.distance
                                                      ? exerData?.set[
                                                          selectedRound - 1
                                                        ]?.distance
                                                      : ""
                                                  }
                                                  onChange={(e) => {
                                                    handleChangeEmomSet(
                                                      e,
                                                      index,
                                                      exerIndex,
                                                      selectedRound - 1
                                                    );
                                                  }}
                                                  type="number"
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                      paddingRight: 0,
                                                    },
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <Select
                                                          id="select-option"
                                                          variant="outlined"
                                                          size="small"
                                                          name="distanceType"
                                                          value={
                                                            exerData.set[
                                                              selectedRound - 1
                                                            ]?.distanceType
                                                          }
                                                          label="Weight Type"
                                                          onChange={(e) => {
                                                            handleChangeEmomSet(
                                                              e,
                                                              index,
                                                              exerIndex,
                                                              selectedRound - 1
                                                            );
                                                          }}
                                                          sx={{
                                                            "& legend": {
                                                              display: "none",
                                                            },
                                                            "& fieldset": {
                                                              top: 0,
                                                            },
                                                            fontSize: "13px",
                                                            border: "none",
                                                            "& fieldset": {
                                                              border: "none",
                                                            },
                                                            "& .MuiOutlinedInput-input":
                                                              {
                                                                padding: 0,
                                                              },
                                                          }}
                                                        >
                                                          <MenuItem value="m">
                                                            Meter
                                                          </MenuItem>
                                                          <MenuItem value="km">
                                                            KM
                                                          </MenuItem>
                                                          <MenuItem value="mi">
                                                            Mile
                                                          </MenuItem>
                                                          <MenuItem value="calories">
                                                            Calories
                                                          </MenuItem>
                                                        </Select>
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                              </div>
                                            )}
                                            {exerData.parameters.includes(
                                              "%MHR"
                                            ) && (
                                              <div className="col-12 col-md-2 col-lg-1 pe-0 py-2">
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="%MHR"
                                                  type="number"
                                                  label="%MHR"
                                                  value={
                                                    exerData.set[
                                                      selectedRound - 1
                                                    ].mhr
                                                      ? exerData.set[
                                                          selectedRound - 1
                                                        ].mhr
                                                      : ""
                                                  }
                                                  name="mhr"
                                                  onChange={(e) => {
                                                    handleChangeEmomSetTempo(
                                                      e,
                                                      index,
                                                      exerIndex,
                                                      selectedRound - 1
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                    },
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",

                                                    fontSize: "13px",
                                                    width: "100%",
                                                    "& .MuiOutlinedInput-input":
                                                      {
                                                        padding: "8px 7px",
                                                      },
                                                  }}
                                                />
                                              </div>
                                            )}
                                            {exerData.parameters.includes(
                                              "Tempo"
                                            ) && (
                                              <div className="col-12 col-md-2 col-lg-1 pe-0 py-2">
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="Tempo"
                                                  type="number"
                                                  label="Tempo"
                                                  value={
                                                    exerData.set[
                                                      selectedRound - 1
                                                    ]?.tempo
                                                      ? exerData.set[
                                                          selectedRound - 1
                                                        ]?.tempo
                                                      : ""
                                                  }
                                                  name="tempo"
                                                  onChange={(e) => {
                                                    handleChangeEmomSetTempo(
                                                      e,
                                                      index,
                                                      exerIndex,
                                                      selectedRound - 1
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                    },
                                                    // readOnly: true,
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",

                                                    fontSize: "13px",
                                                    width: "100%",
                                                    "& .MuiOutlinedInput-input":
                                                      {
                                                        padding: "8px 7px",
                                                      },
                                                  }}
                                                />
                                              </div>
                                            )}
                                            {exerData.parameters.includes(
                                              "Rest"
                                            ) && (
                                              <div className="col-12 col-md-3 col-lg-2 pe-0 py-2">
                                                <TextField
                                                  id="outlined-basic"
                                                  size="small"
                                                  variant="outlined"
                                                  placeholder="0"
                                                  type="number"
                                                  value={
                                                    exerData.set[
                                                      selectedRound - 1
                                                    ]?.rest
                                                      ? exerData.set[
                                                          selectedRound - 1
                                                        ]?.rest
                                                      : ""
                                                  }
                                                  name="rest"
                                                  onChange={(e) => {
                                                    handleChangeEmomSet(
                                                      e,
                                                      index,
                                                      exerIndex,
                                                      selectedRound - 1
                                                    );
                                                  }}
                                                  InputLabelProps={{
                                                    className:
                                                      "textfiels-input-label",
                                                    shrink: true,
                                                    style: {
                                                      fontSize: "14px",
                                                    },
                                                  }}
                                                  label="Rest"
                                                  InputProps={{
                                                    className:
                                                      "textfiels-input-value",
                                                    style: {
                                                      fontSize: "13px",
                                                      paddingRight: 0,
                                                    },
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <Select
                                                          id="select-option"
                                                          variant="outlined"
                                                          size="small"
                                                          name="restType"
                                                          value={
                                                            exerData.set[
                                                              selectedRound - 1
                                                            ]?.restType
                                                          }
                                                          label="Weight Type"
                                                          inputProps={{
                                                            style: {
                                                              padding: 0,
                                                            },
                                                          }}
                                                          onChange={(e) => {
                                                            handleChangeEmomSet(
                                                              e,
                                                              index,
                                                              exerIndex,
                                                              selectedRound - 1
                                                            );
                                                          }}
                                                          sx={{
                                                            "& legend": {
                                                              display: "none",
                                                            },
                                                            "& fieldset": {
                                                              top: 0,
                                                            },
                                                            fontSize: "13px",
                                                            border: "none",
                                                            "& fieldset": {
                                                              border: "none",
                                                            },
                                                            "& .MuiOutlinedInput-input":
                                                              {
                                                                padding: 0,
                                                              },
                                                          }}
                                                        >
                                                          <MenuItem value="sec">
                                                            sec
                                                          </MenuItem>
                                                          <MenuItem value="min">
                                                            min
                                                          </MenuItem>
                                                        </Select>
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                  sx={{
                                                    borderRadius: "5px",
                                                    fontSize: "13px",
                                                    width: "100%",
                                                  }}
                                                />
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      )
                                      // ))
                                    }
                                  </Box>
                                </div>
                              </div>
                            );
                          })}
                        {/* <div
                          className="d-flex align-items-center ms-4 set-parameters mt-1 "
                          onClick={() => {
                            handleAddMoment(index, exerIndex);
                          }}
                        >
                          <AddCircleIcon />
                          <Typography variant="body2" sx={{ ml: 0.2 }}>
                            {`Add Movement`}
                          </Typography>
                        </div> */}
                      </div>
                    );
                  })}
                <div className="row  justify-content-between align-items-center">
                  <div className="col-12 col-md-8 col-lg-10 pe-0 custom_description ">
                    {addArray[mainindex].description && (
                      <>
                        <div className="custom_description_title">
                          Instructions
                        </div>
                        <div className="custom_description_text">
                          {isExpanded
                            ? addArray[mainindex].description
                            : `${addArray[mainindex].description.substring(
                                0,
                                maxLength
                              )}`}
                          {addArray[mainindex].description.length >
                            maxLength && (
                            <span
                              className="toggle-text"
                              style={{
                                color: "var(--portal-theme-primary)",
                                cursor: "pointer",
                                marginLeft: "5px",
                              }}
                              onClick={toggleExpand}
                            >
                              {isExpanded ? "See Less" : "See More"}
                            </span>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-12 col-md-4 col-lg-2 pe-0">
                    <div className="d-flex justify-content-end mt-3">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{ p: "3px" }}
                              checked={addArray[mainindex].is_completed}
                              onChange={(e) =>
                                handleChangeComplete(e, mainindex)
                              }
                            />
                          }
                          label="Mark as Complete"
                        />
                      </FormGroup>
                    </div>
                  </div>
                </div>

                {/* <div className="text-center container my-2">
                  <button
                    className="small-contained-button "
                    onClick={(e) => {
                      handleAddInterval(e);
                    }}
                  >
                    Add interval
                  </button>
                </div> */}
              </Box>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default EMOMExercisesUI;
