import { useRef, useState, useEffect, useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Icon } from "@iconify/react";
import { alpha } from "@mui/material/styles";
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import MenuPopover from "./MenuPopover";
import Scrollbar from "./Scrollbar";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import MicIcon from "@mui/icons-material/Mic";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtmlForChat";
import { useChat } from "src/Hooks/ChatContext";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import {
  getTimeBasedOnDate,
  decode_markdown,
  get_short_string,
} from "src/utils/constants";
import { useSnackbar } from "notistack";
import { _get_unread_user_list } from "src/DAL/Chat/Chat";

export default function ChatPopover() {
  const anchorRef = useRef(null);
  const observer = useRef(null);
  const { unreadMessageCount } = useContentSetting();
  const {
    handleSetSelectedChat,
    chatList,
    get_chat_list,
    chatLoading,
    hasMorePath,
    hasTotalPages,
    hasLoading,
    setHasLoading,
  } = useChat();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChatClick = (chat) => {
    handleSetSelectedChat(chat);
    handleClose();
  };

  const loadMoreData = () => {
    setHasLoading(true);
    get_chat_list(hasMorePath, "", true);
  };

  const lastBookElementRef = useCallback(
    (node) => {
      if (hasLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasTotalPages) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasLoading, hasTotalPages, hasMorePath, observer]
  );

  useEffect(() => {
    if (open) {
      get_chat_list();
    }
  }, [open]);

  return (
    <>
      <IconButton
        ref={anchorRef}
        size="large"
        color={open ? "primary" : "default"}
        className="notification-icons-btn"
        onClick={handleOpen}
        sx={{
          ...(open && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.focusOpacity
              ),
          }),
        }}
      >
        <Badge badgeContent={unreadMessageCount} color="error">
          <Icon icon={"ant-design:message-filled"} width={20} height={20} />
        </Badge>
      </IconButton>
      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 360, border: "none" }}
        className="notification-popover-paper"
      >
        <Box
          sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}
          className="notification-popover"
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Messages</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              You have {unreadMessageCount} unread messages
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Scrollbar
          className="simplebar-content-wrapper"
          sx={{ height: { xs: 340, sm: "auto" } }}
        >
          <List disablePadding>
            {chatLoading ? (
              <div className="d-flex justify-content-center align-items-center mt-5">
                <CircularProgress />
              </div>
            ) : (
              chatList.map((chat, i) => {
                var container = document.createElement("div");
                container.innerHTML = chat?.lastMessage;
                return (
                  <div
                    onClick={() => handleChatClick(chat)}
                    className="chat-list-user hover-effect d-flex p-2"
                    key={i}
                  >
                    <div>
                      <div className="user-profile">
                        <Avatar
                          src={s3baseUrl + chat?.receiver_profile?.profileImage}
                          style={{ height: 50, width: 50 }}
                        ></Avatar>
                        <div
                          className={
                            chat?.receiver_profile?.isOnline
                              ? "online"
                              : "offline"
                          }
                        ></div>
                      </div>
                    </div>
                    <div className="chat-profile-data">
                      <div className="chat-profile-name ps-2 text-capitalize chat-profile-name-padding">
                        {chat?.receiver_profile?.name}
                      </div>
                      <div className="chat-last-message ps-2">
                        {chat?.message_type == "image" ? (
                          <>
                            <PhotoCameraIcon fontSize="10" /> Photo
                          </>
                        ) : chat?.message_type == "video" ? (
                          <>
                            <PlayCircleIcon fontSize="10" /> Video
                          </>
                        ) : chat?.message_type == "html" ? (
                          <>
                            <span className="new_message_text">
                              a new message received
                            </span>
                          </>
                        ) : chat?.message_type == "audio" ? (
                          <>
                            <MicIcon fontSize="18" /> Voice Message
                          </>
                        ) : (
                          chat?.lastMessage &&
                          get_short_string(
                            htmlDecode(decode_markdown(chat?.lastMessage))
                          )
                        )}
                      </div>
                    </div>
                    <div className="chat-last-time text-end ps-2">
                      <Tooltip
                        title={getTimeBasedOnDate(
                          chat?.lastMessageDateTime,
                          "DD MMM YYYY [at] hh:mm A"
                        )}
                      >
                        <span className="">
                          {getTimeBasedOnDate(
                            chat?.lastMessageDateTime,
                            "DD-MM-YYYY hh:mm A"
                          )}
                        </span>
                      </Tooltip>
                    </div>
                    {chat?.my_profile?.unread_message_count > 0 && (
                      <div className="chat-unread-count">
                        {chat?.my_profile?.unread_message_count}
                      </div>
                    )}
                  </div>
                );
              })
            )}
          </List>
          {hasTotalPages ? (
            <div className="text-center mt-3">
              <span
                ref={lastBookElementRef}
                className="mt-3"
                onClick={loadMoreData}
                id="load-more-feed"
              >
                {hasLoading ? (
                  <CircularProgress color="primary" size="1.5rem" />
                ) : (
                  "Load More"
                )}
              </span>
            </div>
          ) : (
            ""
          )}
        </Scrollbar>
        <Divider />
        <Box className="notification-popover" sx={{ p: 1 }}>
          <Button
            fullWidth
            disableRipple
            onClick={() => setOpen(false)}
            component={RouterLink}
            to="/chat"
          >
            View All
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
